.faq {
  background: $bg-dark;
  @include mq(pc) {
    padding: 145px 0 100px;
  }
  @include mq(sp) {
    padding: 100px 0 55px;
  }
  .section__title__sub {
    right: 0;
    @include mq(pc) {
      top: 102px;
    }
    @include mq(sp) {
      top: 25px;
      text-align: right;
    }
  }
  &__items {
    @include mq(pc) {
      margin-top: 3em;
    }
    @include mq(sp) {
      margin-top: 3em;
    }
  }
  &__item {
    box-shadow: $bevel-outside;
    border-radius: 10px;
    margin-top: 2em;
    @include mq(pc) {
      padding: 0 2em;
    }
    @include mq(sp) {
      padding: 0 1.5em;
    }
  }
  &__q {
    display: flex;
    padding: 1.2rem 0;
    @include mq(pc) {
      @include font-size(20, 35);
    }
    @include mq(sp) {
      @include font-size(16, 30);
    }
    &::before {
      content: 'Q';
      @include alnum;
      font-size: #{math.div(20, strip-unit($font-size))}rem;
      color: $primary;
      @include mq(pc) {
        margin-right: 2em;
      }
      @include mq(sp) {
        margin-right: 1.5em;
      }
    }
  }
  &__a {
    display: flex;
    border-top: 1px solid $border;
    padding: 1.5em 0;
    @include line-height(30);
    &::before {
      content: 'A';
      @include alnum;
      font-size: #{math.div(20, strip-unit($font-size))}rem;
      color: $primary;
      @include mq(pc) {
        margin-right: 2em;
      }
      @include mq(sp) {
        margin-right: 1.5em;
      }
    }
  }
}