@use "sass:math";
/* By Athlon Front-End Team */

/* Core variables */
@import "base/vars";

/* CSS Reset */
@import "base/reset";

/* CSS print */
@import "base/print";

/* Fonts */
@import "base/fonts";

/* Mixins, helpers and functions */
@import "base/mixins";

/* Components */
* {
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    font-style: inherit;
    font-weight: inherit;
    box-sizing: border-box;
}

body {
    color: $fg-black;
    background-color: $bg;

    @include mq(sp) {
        font-size: $font-size-sp;
    }

    @include mq(pc) {
        font-size: $font-size-pc;
        overflow-x: hidden;
    }

    letter-spacing: $letter-spacing;
    font-weight: normal;
    line-height: 1;
    font-family: $font-family;
    font-weight: normal;
    width: 100%;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "palt";
}

:active,
:focus {
    outline: none;
}

svg {
    stroke: currentColor;
    fill: currentColor;
}

a,
a:hover,
a:active,
a:visited {
    color: currentColor;
    transition: all 0.2s ease;
}

a,
button {
    &:not(disabled) {
        cursor: pointer;
    }
}

p {
    word-break: break-all;

    strong {
        font-weight: bold;
    }
}

.hidden {
    display: none !important;
}

.is-lazy {
    background-image: none !important;
}

@include mq(sp) {
    .pc {
        display: none !important;
    }
}

@include mq(pc) {
    .sp {
        display: none !important;
    }
}

@mixin alnum($weight: normal) {
    font-family: $font-family-alnum;
    letter-spacing: 0;

    @if $weight =="bold" {
        font-weight: bold;
    }
}

/*@@@@@
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
*/

/* Mixins */
@mixin fg-gradient {
    background: linear-gradient(142deg, rgba(0, 190, 239, 1) 0%, rgba(0, 70, 160, 1) 100%);
    color: transparent;
    background-clip: text;
}

@mixin show-on-load($delay: 4.5, $duration: 1.2, $func: linear) {
    .is-loaded & {
        opacity: 1;
        transition: opacity #{$duration}s $func #{$delay}s;
    }

    opacity: 0;
}

html.is-popup body {
    overflow-y: hidden;
}

@mixin popup {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;

    html.is-popup & {
        opacity: 1;
        visibility: visible;
    }
}

@mixin has-overlay {
    z-index: 2000;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: $bg-overlay;
    mix-blend-mode: multiply;
}

.overlay {
    @include popup;
    @include has-overlay;
}

.modal {
    visibility: hidden;
    z-index: 2010;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &.is-active {
        @include popup;
    }
}

@mixin button-appearance($type, $borderRadius: none) {
    @if "none" !=$borderRadius {
        border-radius: $borderRadius;
    }

    @if $type =="bevel-outside" {
        color: $fg-black;
        background: $button-base;

        &:not(.is-nobevel) {
            box-shadow: $bevel-outside;
        }
    }

    @else if $type =="bevel-inside" {
        color: $fg-black;
        background: $button-base;

        &:not(.is-nobevel) {
            box-shadow: -4px -4px 4px $button-hilight inset, 4px 4px 4px $button-shadow inset;
        }
    }

    @else if $type =="shadow" {
        color: $fg-white;
        background: $black;

        &:not(.is-nobevel) {
            box-shadow: $bevel-outside;
        }
    }

    @else if $type =="primary" {
        position: relative;
        color: $fg-white;
        background: $bg-gradient;

        &:not(.is-nobevel) {
            box-shadow: $bevel-outside;
        }
    }
}

@mixin button {
    line-height: 1.4;
    user-select: none;
    border-radius: #{1.4 * 3}em;
    display: flex;
    align-items: center;
    justify-content: center;
    @include button-appearance(bevel-outside);

    @include mq(pc) {
        padding: 1.4em 5em;
    }

    @include mq(sp) {
        padding: 1.4em 3em;
    }

    @media (hover: hover) {
        &:hover {
            span.icon {
                background-color: $black;

                svg {
                    color: $white;
                }
            }
        }
    }

    span.icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: $white;
        border: 1px solid $white;
        transition: inherit;

        svg {
            width: 6px;
            height: 6px;
            color: $black;
            transition: inherit;
        }
    }

    &.is-pagelink {
        @include font-size(12);
        padding: 13px 13px 13px 2em;
        gap: 14px;
        position: relative;

        @include mq(pc) {
            display: inline-flex;
        }

        @include mq(sp) {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }

        @media (hover: hover) {
            &:hover {
                background-color: $black;
                color: $white;
            }
        }
    }

    &.is-multiline {
        line-height: 1.4;
    }

    &.is-link {
        position: relative;
        background-image: $bg-gradient;

        &:active,
        &:focus,
        &:hover {
            color: white;

            &::before {
                opacity: 0;
            }

            &::after {
                background-color: transparent;
            }
        }

        &::before {
            content: "";
            filter: drop-shadow(0 0 1px $bg);
            background-color: $bg;
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: calc(#{1.4 * 3}em - 2px); // 縁に本体の背景色が見えるのを防止する
            transition: inherit;
        }

        &::after {
            content: "";
            position: absolute;
            left: 1em;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: 28px;
            height: 28px;
            border-radius: 14px;
            background-color: $white;
            border: 1px solid $white;
            transition: inherit;
        }

        span {
            z-index: 1;
        }

        .icon {
            z-index: 1;
            position: absolute;
            left: calc(1em + 15px);
            top: 50%;
            transform: translate(-50%, -50%);
            width: 8px;
            height: 8px;
        }
    }

    &.is-rect {
        @include font-size(14);
        border-radius: $border-radius;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 35px 0;

        img {
            width: 28px;
            height: 28px;
        }
    }

    &.is-nav {
        transition: all 0.2s ease;
        border-radius: 30px;
        width: 60px;
        height: 60px;
        padding: 0 !important;

        .icon {
            width: 5px;
            height: 10px;
        }

        @media (hover: hover) {
            &:hover {
                background-color: $black;
                color: $white;
            }
        }
    }

    .icon {
        pointer-events: none;
    }

    &.is-auto {}
}

.button {
    @include button;
}

.section .button.is-link {
    @include mq(pc) {
        min-width: 340px;
    }

    @include mq(sp) {
        width: 100%;
    }

    &.is-auto {}
}

@mixin is-circle {
    transition: all 0.2s ease;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0 !important;
    color: $black;
    background-color: $white;

    @media (hover: hover) {
        a:hover & {
            background-color: $black;
            color: $white;
        }
    }

    .icon {
        z-index: 1;
        position: absolute;
        left: calc(50% + 1px);
        top: 50%;
        transform: translate(-50%, -50%);
        width: 5px;
        height: 10px;
    }
}

.is-circle {
    @include is-circle;
}

/* Format */
@import "section/format";

/* Typography */
.h0 {
    @include grad-text;
    letter-spacing: 0.1em;

    @include mq(pc) {
        @include font-size(40, 65);
    }

    @include mq(sp) {
        @include font-size(28, 48);
    }
}

.h1 {
    @include mq(pc) {
        @include font-size(25, 43);
    }

    @include mq(sp) {
        @include font-size(20, 30);
    }
}

.h2 {
    @include mq(pc) {
        @include font-size(30, 50);
    }

    @include mq(sp) {
        @include font-size(20, 36);
        margin-top: 2em;
    }
}

.h3 {
    @include h3;
}

.h4 {
    @include h4;
}

.h5 {
    @include h5;
}

.is-alnum {
    @include alnum;
}

.is-grad {
    @include grad-text;
    align-self: flex-start;
    justify-self: flex-start;
}

/* Parts */
.recruit__banner {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: $border-radius;
    box-shadow: $bevel-outside;

    &:hover {
        &::after {
            opacity: 0;
        }
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $fg-gray-light;
        mix-blend-mode: multiply;
        z-index: 3;
        transition: all 0.2s ease;
    }

    &__lead {}

    &__subtitle {
        margin-bottom: 1em;

        @include mq(pc) {
            @include font-size(30, 50);
        }

        @include mq(sp) {
            @include font-size(20, 36);
            margin-top: 2em;
        }
    }

    p {
        @include font-size(16, 30);
        margin-top: 1em;
    }

    &__image {
        &.can-scroll {
            @include mq(pc) {
                margin: 50px -30px;
            }

            @include mq(sp) {
                @include scroller(x);
                margin: 40px -30px 90px;
            }
        }

        &-wrapper {
            @include mq(sp) {
                width: 1080px;
            }
        }

        img {
            width: 100%;
        }
    }

    &.relateds {
        background-color: $bg-dark;
        padding: 70px 0;

        .section__content {
            margin-top: 50px;

            @include mq(pc) {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 40px;
            }

            @include mq(sp) {
                display: flex;
                flex-direction: column;
                gap: 30px;
            }
        }
    }
}

.pagelink {
    @include mq(pc) {
        margin: 60px 0;
    }

    @include mq(sp) {
        margin: 40px 0;
    }

    &__title {
        font-family: $font-family-alnum;
        text-align: center;
        margin-bottom: 2em;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        gap: 18px;
        margin-top: 20px;
    }
}

footer {
    @include mq(pc) {
        padding: 70px 100px 0;
    }

    @include mq(sp) {
        padding: 0 30px;
    }

    small {
        @include alnum;
        padding: 1em 0;
        display: block;
        color: $fg-gray;

        @include mq(pc) {
            @include font-size(12, 72);
            text-align: right;
        }

        @include mq(sp) {
            @include font-size(10, 72);
            text-align: center;
        }
    }
}

.sitemap {
    @include mq(pc) {
        display: grid;
        grid-template-columns: 1fr 220px 220px 220px;
        gap: 30px 40px;

        >li {
            flex-shrink: 0;

            &:nth-child(1) {
                grid-column: 1/2;
                grid-row: 1/3;
            }

            &:nth-child(2) {
                grid-column: 2/3;
                grid-row: 1/2;
            }

            &:nth-child(3) {
                grid-column: 2/3;
                grid-row: 2/5;
            }

            &:nth-child(4) {
                grid-column: 3/4;
                grid-row: 1/2;
            }

            &:nth-child(5) {
                grid-column: 3/4;
                grid-row: 2/3;
            }

            &:nth-child(6) {
                grid-column: 3/4;
                grid-row: 3/5;
            }

            &:nth-child(7) {
                grid-column: 4/5;
                grid-row: 1/2;
            }

            &:nth-child(8) {
                grid-column: 4/5;
                grid-row: 2/3;
            }

            &:nth-child(9) {
                // hidden
                grid-column: 4/5;
                grid-row: 3/4;
            }

            &:nth-child(10) {
                grid-column: 4/5;
                grid-row: 4/5;
            }
        }
    }

    @include mq(sp) {
        >li:not(.sitemap__links) {
            >.sitemap__link {
                @include font-size(14, 24.5);
                padding: 0.8em 0.5em;
                border-top: 1px solid $border;
                display: block;

                &.is-group {
                    position: relative;

                    .action {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 35px;
                        height: 44px;
                        background-image: url(../img/design/icon_plus.svg);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 12px;
                    }

                    &.is-open {
                        padding-bottom: 1.2em;

                        .action {
                            background-image: url(../img/design/icon_minus.svg);
                        }

                        +.sitemap__underlayer {
                            height: auto;
                        }
                    }
                }
            }
        }
    }

    &__logo {
        display: block;

        @include mq(pc) {
            width: 166px;
            height: 67px;
            flex-grow: 1;
        }

        @include mq(sp) {
            width: 154.7px;
            height: 62.4px;
            margin: 50px auto;
        }
    }

    &__links {
        opacity: 0.7;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include mq(pc) {
            gap: 1.5em;
        }

        @include mq(sp) {
            border-top: 1px solid $border;
            padding: 1.5em 0;
            background-color: $gray;
        }

        @include font-size(12);

        .sitemap__link {
            @include mq(sp) {
                padding: 0.6em 0;
            }
        }
    }

    &__underlayer {
        @include font-size(12, 30);

        @include mq(pc) {
            margin-top: 1.5em;
        }

        @include mq(sp) {
            height: 0;
            overflow: hidden;
            transition: 0.3s linear;

            >li:last-child {
                margin-bottom: 1.5em;
            }
        }

        .sitemap__link {
            border: none;
            opacity: 0.7;
            letter-spacing: normal;

            @include mq(sp) {
                padding-left: 2.2em;
            }
        }
    }

    .supplier {
        @include mq(pc) {
            grid-column: 3/5;
            grid-row: 5/6;
            margin-top: 30px;
        }

        @include mq(sp) {
            margin-top: 0.3em;
        }
    }
}

.supplier {
    display: inline-flex;
    box-shadow: $bevel-outside;
    border-radius: $border-radius;

    @include mq(pc) {
        padding: 1.2em 2em;
    }

    @include mq(sp) {
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 1.2em 2em;
    }

    &__title {
        color: black;
        @include font-size(12);

        @include mq(pc) {
            display: flex;
            align-items: center;
            padding-right: 2em;
            margin-right: 2em;
            border-right: 1px solid $border;
        }

        @include mq(sp) {
            width: 100%;
            text-align: center;
            padding-bottom: 1.2em;
            margin-bottom: 1.2em;
            border-bottom: 1px solid $border;
        }
    }

    &__links {
        @include font-size(10);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include mq(pc) {
            padding: 0.6em 0;
            gap: 1.2em;
        }

        @include mq(sp) {
            align-items: center;
            gap: 1em;
        }
    }
}

.items--ordered {
    margin-top: 1em;
    display: flex;
    counter-reset: items-ordered;

    @include mq(pc) {
        justify-content: center;
        gap: 40px;

        &.is-fullwidth {
            justify-content: space-between;
        }
    }

    @include mq(sp) {
        flex-direction: column;
    }

    .item {
        counter-increment: items-ordered;

        @include mq(pc) {
            width: 300px;
            margin-top: 50px;
        }

        @include mq(sp) {
            border-top: 1px solid $border;
            padding: 1em 0 1.5em;
        }

        &::before {
            content: attr(data-prefix) "0" counter(items-ordered);
            @include alnum;
            @include font-size(20, 36);
            color: $primary;
        }

        &:last-child {
            @include mq(sp) {
                //@@@@@border-bottom: 1px solid $border;
            }
        }

        &__title {
            @include mq(pc) {
                @include font-size(20, 36);
            }

            @include mq(sp) {
                @include font-size(18, 36);
            }
        }

        &__image {
            margin: 1em 0;

            @include mq(pc) {
                width: 100%;
                display: flex;
                align-items: center;
            }
        }

        &__desc {
            @include mq(pc) {
                @include font-size(14, 26);
            }

            @include mq(sp) {
                @include font-size(14, 24);
            }
        }
    }
}

.fields {
    display: flex;
    flex-direction: column;
    gap: 0.7em;
}

.field {
    @include font-size(14, 24);

    @include mq(pc) {
        display: flex;
    }

    @include mq(sp) {
        margin: 0.5em 0;
    }

    &__name {
        color: $primary;
        width: 4em;
        flex-shrink: 0;

        @include mq(sp) {
            margin-bottom: 0.5em;
        }
    }

    &__value {
        position: relative;
        display: flex;
        gap: 1.2em;

        @include mq(pc) {
            &::before {
                content: "";
                flex-shrink: 0;
                display: block;
                width: 50px;
                height: 1em;
                border-bottom: 1px solid $border;
            }
        }
    }
}

@mixin service__category($type: normal) {
    border: 1px solid $border;
    border-radius: 1em;

    @if (light==$type) {
        background-color: $white;
        color: $fg-gray;
    }

    @else {
        background-color: $bg;
    }

    @include mq(pc) {
        @include font-size(12, 21);
        padding: 0 1.5em;
    }

    @include mq(sp) {
        @include font-size(12, 17);
        padding: 0.5em 1em;
        width: 100%;
    }
}

@mixin service__link($size: normal) {
    @if (large==$size) {
        @include font-size(16, 30);
        height: 28px;
    }

    @else {
        height: 26px;

        @include mq(pc) {
            @include font-size(12, 21);
        }

        @include mq(sp) {
            @include font-size(12, 17);
        }
    }

    background-color: $black;
    color: white;
    border-radius: 1.2em;
    padding-right: 0.8em;
    padding-left: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.8em;
    position: relative;

    .icon {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        width: 7px;
        height: 7px;
        color: $black;
    }
}

.service {
    &__links {
        display: flex;
        flex-wrap: wrap;

        @include mq(pc) {
            gap: 40px;
            margin-top: 60px;
        }

        @include mq(sp) {
            gap: 20px;
            margin-top: 30px;
        }
    }

    &__link {
        flex-direction: column;
        border-radius: $border-radius;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px;

        @include mq(pc) {
            width: 340px;
            height: 160px;
        }

        &__title {
            @include mq(pc) {
                margin-bottom: 24px;
            }

            @include mq(sp) {
                margin-bottom: 14px;
            }

            display: flex;
            align-items: center;
            gap: 10px;

            span.icon {
                width: 28px;
                height: 28px;

                svg {
                    width: 5px;
                    height: 9px;
                }
            }

            img {
                width: 19px;
                height: 13px;
            }
        }

        &__desc {
            @include font-size(12, 24);
        }
    }

    &__image {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: top;
        }

        &.has-margin {
            @include mq(pc) {
                margin-top: -30px;
                margin-left: -30px;
                margin-right: -30px;
            }

            @include mq(sp) {
                margin-left: -20px;
                margin-right: -20px;
            }
        }
    }

    &__content {
        @include mq(sp) {
            display: flex;
            flex-direction: column;
            gap: 1em;
        }

        @include mq(pc) {
            margin-top: 80px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 0fr 1fr;
            gap: 1em 100px;

            &:nth-child(odd) {
                h3 {
                    grid-column: 1/2;
                    grid-row: 1/2;
                }

                >.service__image,
                >img {
                    grid-column: 2/3;
                    grid-row: 1/3;
                }

                p {
                    grid-column: 1/2;
                    grid-row: 2/3;
                }
            }

            &:nth-child(even) {
                h3 {
                    grid-column: 2/3;
                    grid-row: 1/2;
                }

                >.service__image,
                >img {
                    grid-column: 1/2;
                    grid-row: 1/3;
                }

                p {
                    grid-column: 2/3;
                    grid-row: 2/3;
                }
            }
        }

        h2 {
            @include h2;

            @include mq(sp) {
                margin: 1em 0;
            }
        }

        h3 {
            @include h3;

            @include mq(sp) {
                margin: 1em 0;
            }
        }
    }
}

@mixin card__media($parentClass) {
    .#{$parentClass}__card__media {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #e1e1e1;
            mix-blend-mode: multiply;
            z-index: 12;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }
    }

    &:hover {
        .#{$parentClass}__card__media::after {
            opacity: 0;
        }
    }
}

@mixin card($height, $heightMedia, $heightContent, $heightMediaSp: 160) {
    .case__card {
        position: relative;
        box-shadow: $bevel-outside;
        border-radius: $border-radius;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        &:hover {
            .case__card__media::after {
                opacity: 0;
            }
        }

        @include mq(pc) {
            width: 320px;
            height: #{$height}px;
        }

        @include mq(sp) {
            height: auto; // to stretch
            flex-direction: column;
        }

        &__media {
            position: relative;
            flex-shrink: 0;
            width: 100%;

            @include mq(pc) {
                height: #{$heightMedia}px;
            }

            @include mq(sp) {
                height: #{$heightMediaSp}px;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $fg-gray-light;
                mix-blend-mode: multiply;
                z-index: 12;
                transition: all 0.2s ease;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
                z-index: 11;
            }
        }

        &__content {
            @include mq(pc) {
                z-index: 13;
                position: absolute;
                left: 0;
                bottom: 20px;
                width: 280px;
                height: #{$heightContent}px;
            }

            @include mq(sp) {
                flex-grow: 1;
                margin-bottom: 20px;
            }

            display: flex;
            gap: 1em;
            flex-direction: column;
            align-items: self-start;
            justify-content: flex-start;
            padding: 1em;
            background-color: $white;
        }

        &__title {
            @include font-size(14, 24);
            display: -webkit-box;
            box-orient: vertical;
            line-clamp: 3;
            overflow: hidden;
            //margin: 1em 0;
            min-height: 1px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        &__name {
            //margin: .8em 0 1.2em;
        }

        &__div {
            @include alnum;
            @include font-size(12, 19);
            color: $fg-gray;
        }

        &__meta,
        &__categories {
            display: flex;
            // flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 2px;
        }

        &__category {
            @include font-size(12, 21);
            border: 1px solid $border;
            border-radius: 1em;
            background-color: $bg;
            padding: 0 0.8em;
            margin: 2px 0;
            letter-spacing: 0;
        }

        &__company {
            @include font-size(12, 21);
            color: $fg-gray;
        }

        &__type {
            @include font-size(12, 21);
            color: $fg-gray;
        }

        &__desc {
            @include font-size(14, 24);
        }

        &__join {
            @include text--xs;
        }
    }
}

.company__card {
    @include mq(pc) {
        width: 1100px;
        height: 420px;
    }

    @include mq(sp) {
        width: calc(100% - 60px);
    }

    &__media {
        width: 100%;
        height: 100%;
    }
}

.service__card {
    box-shadow: $bevel-outside;
    border-radius: $border-radius;
    overflow: hidden;
    display: flex;

    &:hover {
        .service__card__media::after {
            opacity: 0;
        }
    }

    @include mq(sp) {
        height: auto; // to stretch
        flex-direction: column;
    }

    &__media {
        position: relative;
        flex-shrink: 0;

        @include mq(pc) {
            width: 300px;
        }

        @include mq(sp) {
            width: 100%;
            height: 150px;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $fg-gray-light;
            mix-blend-mode: multiply;
            z-index: 12;
            transition: all 0.2s ease;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            z-index: 11;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: self-start;

        @include mq(pc) {
            padding: 45px 45px 0;
        }

        @include mq(sp) {
            padding: 20px;
        }
    }

    &__title {
        @include mq(pc) {
            @include font-size(20, 36);
        }

        @include mq(sp) {
            @include font-size(18, 28);
        }
    }

    &__category {
        @include service__category(light);

        @include mq(pc) {
            margin: 1em 0 2em;
        }

        @include mq(sp) {
            margin: 1em 0;
            text-align: center;
        }
    }

    &__desc {
        @include mq(pc) {
            @include font-size(16, 30);
        }

        @include mq(sp) {
            @include font-size(14, 24);
        }
    }

    &__details {
        margin-top: 1.5em;
        border-top: 1px solid $border;
        display: flex;
        flex-wrap: wrap;
        color: $white;

        @include mq(pc) {
            padding-top: 1.5em;
            gap: 10px;
        }

        @include mq(sp) {
            padding-top: 1.2em;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
        }

        &::before {
            content: "サービス詳細";
            width: 100%;
            margin-bottom: 0.2em;
            color: $black;

            @include mq(sp) {
                @include font-size(14, 24);
            }
        }
    }

    &__detail {
        @include service__link(large);
    }
}

.consultant__card {
    position: relative;
    box-shadow: $bevel-outside;
    border-radius: $border-radius;
    overflow: hidden;

    @include mq(pc) {
        width: 320px;
        height: 500px;
    }

    @include mq(sp) {
        height: 450px;
        flex-direction: column;
    }

    &:hover {
        &::after {
            opacity: 0;
        }
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $fg-gray-light;
        mix-blend-mode: multiply;
        z-index: 3;
        transition: all 0.2s ease;
    }

    &__media {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 1.5em 1em;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 260px;
            background-image: $bg-gradient;
            mix-blend-mode: multiply;
            /* multiply の場合、skew では 左下が card の border-radius を超えて表示されてしまうため、clip-path にする
      transform: skew(0, -25deg);  
      transform-origin: right top;
      */
            clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 156px);
            z-index: 1;

            @include mq(pc) {
                bottom: -25px;
            }

            @include mq(sp) {
                bottom: -35px;
            }
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 260px;
            background-color: $black;
            transform: skew(0, -26deg);
            transform-origin: right top;
            z-index: 2;

            @include mq(pc) {
                bottom: -133px;
            }

            @include mq(sp) {
                bottom: -138px;
            }
        }
    }

    &__genre {
        @include font-size(12, 24);
        flex-grow: 1;

        &::before {
            content: "専門分野";
            display: block;
            margin-bottom: 1em;
            @include font-size(14, 24, 12);
        }
    }

    &__div {
        @include alnum;
        @include font-size(12, 19);
        color: $white;
        text-align: right;
        margin-bottom: 1em;
        position: relative;
        z-index: 4;
    }

    &__name {
        color: $white;
        text-align: right;
        z-index: 4;
    }

    &.is-tutor {
        .consultant__card {
            &__content {
                &::before {
                    bottom: 77px;
                }

                &::after {
                    bottom: 26px;
                }
            }

            &__div {
                color: $black;
            }

            &__name {
                color: $black;
            }
        }
    }
}

.tutor__card {
    position: relative;
    box-shadow: $bevel-outside;
    border-radius: $border-radius;
    overflow: hidden;

    @include mq(pc) {
        width: 320px;
        height: 500px;
    }

    @include mq(sp) {
        height: 450px;
        flex-direction: column;
    }

    &__media {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 1.5em 1em;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 260px;
            background-image: $bg-gradient;
            mix-blend-mode: multiply;
            /* multiply の場合、skew では 左下が card の border-radius を超えて表示されてしまうため、clip-path にする
      transform: skew(0, -25deg);  
      transform-origin: right top;
      */
            z-index: 1;

            @include mq(pc) {
                bottom: 67px;
                clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 145px);
            }

            @include mq(sp) {
                bottom: 37px;
                clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 123px);
            }
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 260px;
            background-color: $black;
            transform: skew(0, -24deg);
            transform-origin: right top;
            z-index: 2;

            @include mq(pc) {
                bottom: -32px;
            }

            @include mq(sp) {
                bottom: -55px;
            }
        }
    }

    &__div {
        @include alnum;
        @include font-size(12, 19);
        margin-bottom: 1em;
    }

    &__name {
        flex-grow: 1;
    }

    &__desc {
        color: $white;
        z-index: 4;

        @include mq(pc) {
            @include font-size(16, 28);
            margin-bottom: 1em;
        }

        @include mq(sp) {
            @include font-size(14, 24);
            margin-bottom: 2em;
            letter-spacing: 1px;
        }
    }

    &__metas {
        @include mq(pc) {
            min-height: 80px;
            gap: 15px;
        }

        @include mq(sp) {
            gap: 10px;
        }

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__meta {
        color: white;
        position: relative;
        z-index: 4;
        display: flex;

        &:last-child {
            .tutor__card__meta__value {
                padding-left: .7em;
                text-indent: -.7em;
            }
        }

        &__key {
            white-space: nowrap;

            @include mq(pc) {
                @include font-size(14, 14);
            }

            @include mq(sp) {
                @include font-size(12, 12);
            }
        }

        &__values {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            padding-left: 12px;
            border-left: 1px solid white;

            @include mq(pc) {
                gap: 10px;
            }

            @include mq(sp) {
                gap: 5px;
            }
        }

        &__value {
            @include font-size(12, 14);
            letter-spacing: 1px;
        }
    }
}

.info__card {
    @include card__media(info);
    height: 320px;
    box-shadow: $bevel-outside;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @include mq(pc) {
        width: 370px;
    }

    &__media {
        height: 200px;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 20px;
            right: 22px;
            width: 41px;
            height: 13px;
            background-image: url("../img/design/hipus.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right top;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__title {
        color: white;
        padding: 0 30px 30px;
        position: absolute;
        left: 0;
        bottom: 0;

        @include mq(pc) {
            @include font-size(20, 35);
        }

        @include mq(sp) {
            @include font-size(16, 30);
        }
    }

    &__content {
        height: 120px;
        padding: 26px 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.6em;
    }

    &__date {
        @include font-size(12, 21);
        display: inline-block;
        opacity: 0.7;
        margin-right: 0.7em;
    }

    &__category {
        @include font-size(12, 21);
        display: inline-block;
        border: 1px solid $border;
        border-radius: 1.1em;
        background-color: $black;
        color: white;
        padding: 0.2em 1em;
    }

    &__subcategory {
        @include font-size(12, 21);
        display: inline-block;
        border: 1px solid $border;
        border-radius: 1.1em;
        background-color: $white;
        color: $fg-gray;
        padding: 0.2em 1em;
    }
}

/* Vendor Override */
.swiper {
    @include mq(pc) {
        padding: 60px 0;
    }

    @include mq(sp) {
        padding: 50px 0;

        &-slide {
            width: calc(100% - 100px);
        }
    }

    &.is-linear {
        .swiper-wrapper {
            transition-timing-function: linear;
        }
    }
}

.swiper__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq(sp) {
        .is-nav {
            display: none;
        }
    }
}

@mixin case__swiper($height: 460, $heightMedia: 260, $heightContent: 270, $heightMediaSp: 160) {
    @include card($height, $heightMedia, $heightContent, $heightMediaSp);
}

/* Layouts */
.container {
    position: relative;

    @include mq(pc) {
        width: 1100px;
        margin: 0 auto;
    }

    @include mq(sp) {
        width: 100%;
        padding: 0 30px;
    }
}

.has-sidebar {
    main {
        background: $bg-gradient-gray;

        .main {
            display: flex;
            gap: 60px;

            @include mq(pc) {
                padding: 2em 0 120px;
            }

            @include mq(sp) {
                padding-top: 2em;
                padding-bottom: 60px;
                flex-direction: column;
            }
        }

        .content {
            display: flex;

            @include mq(pc) {
                gap: 30px;
                flex-wrap: wrap;
                flex-grow: 1;
            }

            @include mq(sp) {
                flex-direction: column;
                gap: 30px;
            }
        }

        .sidebar {
            display: flex;
            flex-direction: column;
            gap: 30px;

            @include mq(pc) {
                flex: 0 0 260px;
            }

            .categories {
                border-radius: $border-radius;
                overflow: hidden;
                display: flex;
                flex-direction: column;

                &__title {
                    background-color: $black;
                    color: white;
                    height: 50px;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                }

                &__link {
                    @include font-size(14);
                    height: 40px;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    border-top: 1px solid $border;
                    background-color: white;
                }
            }

            .banner {
                box-shadow: $bevel-outside;
                border-radius: $border-radius;
                overflow: hidden;

                img {
                    width: 100%;
                }
            }
        }
    }
}

footer {
    @include mq(pc) {
        padding: 70px 100px 0;
    }

    @include mq(sp) {
        padding: 0 30px;
    }

    small {
        @include alnum;
        padding: 1em 0;
        display: block;
        color: $fg-gray;

        @include mq(pc) {
            @include font-size(12, 72);
            text-align: right;
        }

        @include mq(sp) {
            @include font-size(10, 72);
            text-align: center;
        }
    }
}

/* Parts */
@import "section/card";

/* Sections */
section {
    position: relative;
    z-index: 0;
}

.section {
    &:not(.mv) {
        @include mq(pc) {
            padding: 90px 0;

            .section__title {
                //@include font-size(25, 43);
            }
        }

        @include mq(sp) {
            padding: 80px 0;

            .section__title {
                // @include font-size(20, 30);
            }
        }
    }

    &.mv {
        +.section {
            @include mq(pc) {
                padding-top: 2em;
            }

            @include mq(sp) {
                padding-top: 3em;
            }
        }
    }

    &__title {
        @include heading_marker;
        position: relative;
        letter-spacing: 0.15em;
        padding-top: 24px;
        @include h3;

        +.h2 {
            @include mq(pc) {
                margin: 2em 0 1em;
            }

            @include mq(sp) {
                margin: 2em 0 1em;
            }
        }
    }

    &__lead {}

    &__subtitle {
        margin-bottom: 1em;

        @include mq(pc) {
            @include font-size(30, 50);
        }

        @include mq(sp) {
            @include font-size(20, 36);
            margin-top: 2em;
        }
    }

    p {
        @include font-size(16, 30);
        margin-top: 1em;
    }

    &__image {
        &.can-scroll {
            @include mq(pc) {
                margin: 50px -30px;
            }

            @include mq(sp) {
                @include scroller(x);
                margin: 40px -30px 90px;
            }
        }

        &-wrapper {
            @include mq(sp) {
                width: 1080px;
            }
        }

        img {
            width: 100%;
        }
    }

    &.relateds {
        background-color: $bg-dark;
        padding: 70px 0;

        .section__content {
            margin-top: 50px;

            @include mq(pc) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                gap: 40px;
            }

            @include mq(sp) {
                display: flex;
                flex-direction: column;
                gap: 30px;
            }
        }
    }
}

@import "section/menu";
@import "section/home_mv";
@import "section/home_service";
@import "section/home_case";
@import "section/home_company";
@import "section/home_consultant";
@import "section/home_recruit";
@import "section/home_news";
@import "section/home_contact";
@import "section/faq";

/* Adjust */
body.home {
    .section__title {
        @include mq(pc) {
            @include font-size(40, 50);
        }

        @include mq(sp) {
            @include font-size(28, 48);
        }
    }
}

/* Pages */
@import "pages/page-feature";
@import "pages/page-service";
@import "pages/single-service";
@import "pages/single-partner";
@import "pages/page-case_study";
@import "pages/single-case_study";
@import "pages/page-company";
@import "pages/page-consultants";
@import "pages/single-consultant";
@import "pages/page-info";
@import "pages/single-info";
@import "pages/page-contact";
@import "pages/page-recruitment";
@import "pages/page-supplier.scss";
@import "pages/page-privacy.scss";
@import "pages/page-termofuse.scss";
@import "pages/page-message.scss";
@import "pages/page-thanks.scss";
@import "pages/page-en_profile.scss";
@import "pages/page-talk.scss";

/* Browser specific styles */
@import "dirty/hacks";
