body.page-company {
  @include mv-wave('../img/design/company_bg.jpg'); 

  main {
    background: $bg-gradient-gray;
  }

  .ov{
    padding-bottom: 0;
  }

  .index {
    .container {
      display: flex;
      @include mq(pc) {
        gap: 70px;
      }
      @include mq(sp) {
        flex-direction: column;
        gap: 30px;
      }
    }
    .card {
      @include Card;
      @include hover--w-b('span.icon');
      &__title {
        @include headingtext(46, nohover);
        width: 100%;
        .icon {
          position: absolute;
          right: 0;
        }
      }
      &__media {
        @include mq(pc) {
          height: 214px;
        }
        @include mq(sp) {
          height: 164px;
        }       
      }
    }
  }

  .recruit {
    @include mq(pc) {
      padding-bottom: 160px;
    }
    @include mq(sp) {
      padding-bottom: 80px;
    }      
  }

  .outline {
    .buttons{
      margin-top: 50px; 
      @include mq(sp) {
        margin-top: 30px; 
      }      
    }
    .inline-block{
      display: inline-block;
    }
    .googlemap{
      margin-top: 50px;
      width: 100%;
      height: 400px;
      @include mq(sp) {
        height: 220px;
       }
      > iframe{
        width: 100%;
        height: 100%;
        max-width: 100%;
      }
    }

    &__col {
      .outline-table {
        width: 100%;
        > tbody {
          display: flex;
          flex-direction: column;
          @include mq(pc) {
            @include text;
          }
          @include mq(sp) {
           @include text--s;
          }
          tr {
            display: flex;
            margin: 0 0;
            width: 100%;
            border-top: 1px solid $border;
            border-left: 1px solid $border;
            border-right: 1px solid $border;

            &:last-of-type{
              border-bottom: 1px solid $border;
            }
            @include mq(sp) {
              flex-wrap: wrap;
             }
          }
          th {
            padding: .8em 1.5em;
            background-color: white;
            flex-shrink: 0;
            text-align: left;
            font-weight: 500;
            @include mq(pc) {
              width: 10em;
            }
            @include mq(sp) {
              width: 100%;
            }
          }
          td {
            padding: .8em 1.5em;
            padding-right: 14%;
            flex-grow: 1;

            @include mq(sp) {
              width: 100%;
              padding: .8em 1.5em;
            }

            .wrap-part{
              @include mq(sp) {
              display: block;
              }

              &:not(:first-of-type){
                @include mq(sp) {
                 padding-left: 1em;
                  }
              }
            }
          }

          
          .inner-table{
            width: 100%;

            > tbody {
              @include text;
              display: flex;
              flex-direction: column;
              tr {
                display: flex;
                width: 100%;
                border: none;
              }
              td:nth-of-type(1) {
                padding: 0 0;
                flex-shrink: 0;
                text-align: left;
                width: 8em;
                flex-grow: 0;
              }
              td:nth-of-type(2) {
                width: 65%;
                text-align: left;
                padding: 0 .8em;
                flex-grow: 1;
                @include mq(sp) {
                  width: 15%;
                  padding: 0 1.5em;
                }
              }
          }

          &.inner-table--wrap{
            > tbody {
              tr {
                @include mq(sp) {
                flex-wrap: wrap;
                }
              }
              td:nth-of-type(1) {
                @include mq(sp) {
                  width: 100%;
                }
              }
              td:nth-of-type(2) {
                @include mq(sp) {
                  width: 100%;
                  padding-left: 1em;
                }
              }
          }
      }
      }
    }
  }
}
}
}
