body.page-contact {
    @include mv-wave("../img/design/feature_bg.jpg");

    #menu {
        position: relative;
    }
    main {
        background: $bg-gradient-gray;

        @include mq(pc) {
            margin-top: -100px; // ヘッダー追従解除
        }

        @include mq(sp) {
            margin-top: -85px;
        }
    }
    .form {
        padding-top: 2em;
        @include mq(pc) {
            padding-bottom: 120px;
        }
        @include mq(sp) {
            padding-bottom: 60px;
        }
        iframe[src=""] {
            width: 100%;
            min-height: 80vh;
            background-color: white;
        }
    }
}
