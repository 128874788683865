body.home {
#company__swiper {
  @include mq(pc) {
    padding-bottom: 60px;
  }
  @include mq(sp) {
    padding-bottom: 20px;
  }
  .swiper {
    @include mq(pc) {
      padding: 60px 0;
    }
    @include mq(sp) {
      padding: 50px 0;
    }
  }
  .swiper__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(pc) {
      gap: 40px;
    }
    @include mq(sp) {
      gap: 30px;
      flex-direction: column;
    }
  }
}
.company {
  @include mq(pc) {
    margin-top: -160px;
    padding-top: 225px;
    // https://wave.novoselski.net/
    clip-path: polygon(100% 100%, 0% 100% ,
      0.00% #{80.66 * 1.5}px,
      5.00% #{88.61 * 1.5}px,
      10.00% #{94.18 * 1.5}px, 
      15.00% #{97.01 * 1.5}px, 
      20.00% #{96.92 * 1.5}px, 
      25.00% #{93.94 * 1.5}px, 
      30.00% #{88.23 * 1.5}px, 
      35.00% #{80.16 * 1.5}px, 
      40.00% #{70.24 * 1.5}px, 
      45.00% #{59.09 * 1.5}px, 
      50.00% #{47.41 * 1.5}px, 
      55.00% #{35.94 * 1.5}px, 
      60.00% #{25.39 * 1.5}px, 
      65.00% #{16.43 * 1.5}px, 
      70.00% #{9.62 * 1.5}px, 
      75.00% #{5.39 * 1.5}px, 
      80.00% #{4.00 * 1.5}px, 
      85.00% #{5.55 * 1.5}px, 
      90.00% #{9.93 * 1.5}px, 
      95.00% #{16.87 * 1.5}px, 
      100.00% #{25.94 * 1.5}px
    );
  }
  @include mq(sp) {
    margin-top: -60px;
    padding-top: 110px;
    // https://wave.novoselski.net/
    clip-path: polygon(100% 100%, 0% 100%, 
      0.00% #{95.74 * 0.6}px,
      5.00% #{92.68 * 0.6}px,
      10.00% #{88.13 * 0.6}px,
      15.00% #{82.25 * 0.6}px,
      20.00% #{75.26 * 0.6}px,
      25.00% #{67.39 * 0.6}px,
      30.00% #{58.93 * 0.6}px,
      35.00% #{50.18 * 0.6}px,
      40.00% #{41.44 * 0.6}px,
      45.00% #{33.03 * 0.6}px,
      50.00% #{25.25 * 0.6}px,
      55.00% #{18.37 * 0.6}px,
      60.00% #{12.63 * 0.6}px,
      65.00% #{8.24 * 0.6}px,
      70.00% #{5.35 * 0.6}px,
      75.00% #{4.06 * 0.6}px,
      80.00% #{4.43 * 0.6}px,
      85.00% #{6.44 * 0.6}px,
      90.00% #{10.01 * 0.6}px,
      95.00% #{15.02 * 0.6}px,
      100.00% #{21.30 * 0.6}px
    );
  }
  background: linear-gradient(0deg, rgba($bg-dark,1) 0%, rgba($bg,1) 100%);
}
}