/**
 * Helper mixins
*/

// Clearfix
// http://www.cssmojo.com/latest_new_clearfix_so_far/
// Other clearfix options: https://github.com/stubbornella/oocss/blob/master/oocss/src/components/utils/_clearfix-me.scss
@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

// Media queries
@mixin bp($point) {
  @if $point == large {
    @media (min-width: 1280px) { @content; }
  } @else if $point == small {
    @media (max-width: 1279px) { @content; }
  }
}

// Text selection
@mixin text-selection($color-selection, $color-selection-bg) {
  ::-moz-selection { // sass-lint:disable-line no-vendor-prefixes
    background: $color-selection-bg;
    color: $color-selection;
    text-shadow: none;
  }

  ::selection {
    background: $color-selection-bg;
    color: $color-selection;
    text-shadow: none;
  }
}

//Placeholders
//Usage:
//@include placeholder;
@mixin placeholder {
  // sass-lint:disable no-vendor-prefixes
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}


// 追加


@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }
  @return $number;
}

@mixin line-height($size) {
  line-height: math.div($size, strip-unit($font-size));
}

@mixin font-size($size, $line-height: none, $baseSize: none, $unit:em) {
  $size_: $size;
  @if $baseSize != none {
    @include mq(sp) {
      $size_: math.div(strip-unit($font-size-sp) * $size, $baseSize);
    }
    @include mq(pc) {
      $size_: math.div(strip-unit($font-size-pc) * $size, $baseSize);
    }
  }
  @include mq(sp) {
    font-size: #{math.div($size_, strip-unit($font-size-sp))}#{$unit};
  }
  @include mq(pc) {
    font-size: #{math.div($size_, strip-unit($font-size-pc))}#{$unit};
  }
  @if $line-height != none {
    line-height: math.div($line-height, $size_);
  }
}

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}


@mixin scroller($dir) {
  overflow-#{$dir}: scroll;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: $bevel-inside;
    border-radius: 8px;
    margin: 0 30px;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 8px;
  }
}
