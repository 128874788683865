body.single-partner {
  @include mv-wave('../img/design/service_bg.jpg', simple); 

  .ov {
    background: $bg-gradient-gray;
    padding-top: 0 !important;
    position: relative;
    &__title {
      @include h2;
      margin: 1.5em 0 1em;
    }
    &__logo {
      @include mq(pc) {
        position: absolute;
        bottom: 100%;
        right: 0;
      }
      @include mq(sp) {
        margin-top: 1.5em;
      }
    }
  }

  .contact {
    background: $bg-gradient-gray;
    .section__content {
      margin-top: 40px;
    }
  }

}