body.single-service {
  #voice__swiper {
    @include case__swiper(700, 280, 500, 220);

    @include mq(pc) {
      .swiper-wrapper {
        justify-content: center;
      }

      #case__swiper-navigation .is-nav {
        visibility: hidden;
        pointer-events: none;
      }
    }
  }

  #case__swiper {
    @include case__swiper;
  }

  @include mv-wave;

  #tutor__swiper {
    .swiper {
      padding: 60px 0;
    }
  }

  .mv {
    color: white;

    &__bg {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .breadcrumb {
      color: white;
    }
  }

  .issue {
    border-radius: $border-radius;
    background-image: $bg-gradient-secondary;
    padding: 30px 40px 40px;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1em;

    &__title {
      @include h4;
    }

    &__items {
      display: flex;
      flex-direction: column;
      gap: 1em;
    }

    &__item {
      @include text;
      padding-left: .8em;
      text-indent: -.7em;
    }
  }

  .ov {
    padding: 0;

    &__title {
      @include h2;
      margin: 1.5em 0 1em;
    }
  }

  .strength {
    background-color: $bg-dark;
    position: relative;
    @include has-wave-after;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      background-color: $bg;
      clip-path: polygon(100% 0%, 0% 0%, 0.00% 72.61%, 4.00% 72.13%, 8.00% 70.69%, 12.00% 68.34%, 16.00% 65.13%, 20.00% 61.16%, 24.00% 56.52%, 28.00% 51.35%, 32.00% 45.77%, 36.00% 39.95%, 40.00% 34.02%, 44.00% 28.16%, 48.00% 22.52%, 52.00% 17.24%, 56.00% 12.47%, 60.00% 8.33%, 64.00% 4.94%, 68.00% 2.38%, 72.00% 0.73%, 76.00% 0.03%, 80.00% 0.29%, 84.00% 1.51%, 88.00% 3.65%, 92.00% 6.67%, 96.00% 10.47%, 100.00% 14.97%);
    }

    @include mq(pc) {
      padding: 45px 0 150px;

      &::before {
        height: 460px;
      }
    }

    @include mq(sp) {
      padding: 20px 0 100px;

      &::before {
        height: 120px;
      }
    }

    .item {
      @include mq(pc) {}

      &__image {
        img {
          width: 100%;
          margin: 1em auto 0;
        }
      }
    }
  }

  .solution {
    @include has-wave-before;
    @include has-wave-after;
  }

  .lineup {
    @include has-wave-before;
    //@@@FAQ @include has-wave-after;
  }

  .attitude {
    @include has-wave-before;
    @include has-wave-after;

    @include mq(pc) {
      &::after {
        height: 450px;
        bottom: 0;
        clip-path: polygon(100% 100%, 0% 100%, 0.00% 0.00%, 5.00% 0.89%, 10.00% 3.51%, 15.00% 7.78%, 20.00% 13.55%, 25.00% 20.61%, 30.00% 28.71%, 35.00% 37.57%, 40.00% 46.86%, 45.00% 56.27%, 50.00% 65.45%, 55.00% 74.09%, 60.00% 81.87%, 65.00% 88.53%, 70.00% 93.82%, 75.00% 97.55%, 80.00% 99.61%, 85.00% 99.90%, 90.00% 98.43%, 95.00% 95.24%, 100.00% 90.45%);
      }
    }
  }

  .project {
    background: $bg-gradient-gray;

    @include mq(pc) {
      padding-top: 0;
    }

    @include mq(sp) {
      @include has-wave-before;

      .section__image {
        margin-bottom: 30px;
      }
    }

    //@@@FAQ @include has-wave-after;
  }

  .faq {
    @include has-wave-before;
  }

  .pickup,
  .voice {
    background: $bg-gradient-gray;
  }

  .tutor {

    //@@@FAQ background: $bg-gradient-gray;
    @include mq(pc) {
      padding-bottom: 130px;
    }
  }

  .contact {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../img/design/consulting_contact_bg.jpg');

    @include mq(pc) {
      height: 460px;
    }

    @include mq(sp) {
      padding: 4em 0;
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }

    img {
      @include mq(sp) {
        width: 60px;
      }
    }

    h2 {
      @include h2;
      margin-top: 0;
      color: white;
    }

    p {
      margin-top: 0;
      color: white;

      @include mq(sp) {
        @include font-size(14, 24);
      }
    }

    .button {
      box-shadow: none;
      margin-top: 2.5em;
    }
  }

  .service {
    &__videos {
      display: flex;

      @include mq(pc) {
        margin: 60px 0;
        justify-content: space-evenly;
        gap: 50px;
      }

      @include mq(sp) {
        margin: 30px 0;
        flex-direction: column;
        gap: 30px;
      }
    }

    &__video {
      display: flex;
      flex-direction: column;
      gap: 1em;
    }

    &__images {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;

      @include mq(pc) {
        margin-top: 60px;

        p:first-child {
          width: calc(50% - 90px);
        }
      }

      @include mq(sp) {
        margin-top: 30px;
      }
    }

    &__cols {
      display: flex;
      counter-reset: col;

      @include mq(pc) {
        margin-top: 60px;
        flex-wrap: wrap;
        gap: 100px;
      }

      @include mq(sp) {
        margin-top: 30px;
        flex-direction: column;
        gap: 30px;
      }
    }

    &__col {
      @include mq(pc) {
        width: 500px;
      }

      &.is-ordered {
        @include has-ordered-heading(col);

        @include mq(sp) {
          &::before {
            top: -.1em;
          }
        }
      }

      .h2 {
        line-height: 1.4;

        span {
          @include font-size(20, 20, 30);
        }
      }

      p {
        margin: 1em 0;
      }

      table {
        width: 100%;

        @include mq(pc) {
          margin: 30px 0 0;
        }
      }

      tbody {
        display: flex;
        flex-direction: column;

        tr {
          display: flex;
          margin: 2px 0;
          width: 100%;
        }

        th {
          padding: .8em 1.5em;
          background-color: $border;
          color: $fg-gray;
          flex-shrink: 0;
          text-align: left;

          @include mq(pc) {
            width: 8em;
          }
        }

        td {
          padding: .8em 1.5em;
          background-color: white;
          flex-grow: 1;
        }
      }
    }
  }

  .course {
    background-color: white;
    padding: 1.5em;
    height: 100%;

    &__title {
      @include h5;
      margin-bottom: 1em;
      padding-bottom: .5em;
      border-bottom: 1px solid $border;
    }

    &__items {
      counter-reset: course-item;
      list-style: none;

      @include mq(pc) {
        &.is-cols {
          column-count: 2;
        }
      }
    }

    li {
      @include text--s;
      counter-increment: course-item;
      padding: .2em 0 .2em 1.5em;
      text-indent: -1.5em;
      letter-spacing: normal;

      &::before {
        content: counter(course-item);
        @include alnum(bold);
        color: $primary;
        margin-right: 1em;
      }
    }
  }

  .training {
    &__banner {
      box-shadow: $bevel-outside;
      border-radius: $border-radius;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url('../img/design/consulting_contact_bg.jpg');
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 3em 30px;

      @include mq(pc) {
        margin-top: 4em;
      }

      @include mq(sp) {
        margin-top: 1em;
      }

      img {
        @include mq(pc) {
          width: 64px;
        }

        @include mq(sp) {
          width: 40px;
        }
      }

      h4 {
        @include h4;
        margin-top: 0;
        color: white;
      }

      .button {
        box-shadow: none;
        margin-top: 1em;
      }
    }
  }

  .consultant {
    padding-top: 2em;

    @include mq(pc) {
      padding-bottom: 120px;
    }

    @include mq(sp) {
      padding-bottom: 60px;
    }

    &__cards {
      @include card(540, 280, 320);
      display: flex;

      @include mq(pc) {
        padding: 60px 0;
        flex-wrap: wrap;
        gap: 70px;
      }

      @include mq(sp) {
        padding: 3em 0;
        flex-direction: column;
        gap: 30px;
      }
    }
  }

  .news {
    background: $bg-gradient-gray;
  }

  .case-b {
    $border: #e1e1e1;
    $border-radius: 10px;
    $fg-gray-light: #e1e1e1;

    &__list {
      margin-top: 90px;

      @include mq(sp) {
        margin-top: 40px;
      }
    }

    &__card {
      box-shadow: $bevel-outside;
      border-radius: $border-radius;
      overflow: hidden;
      display: flex;

      @include mq(sp) {
        flex-direction: column;
      }

      @include mq(pc) {
        height: 155px;

        &:hover {
          .case__card__media::after {
            opacity: 0;
          }
        }
      }


    }

    &__media {
      position: relative;
      flex-shrink: 0;
      width: 210px;

      @include mq(sp) {
        width: 100%;
        height: 160px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $fg-gray-light;
        mix-blend-mode: multiply;
        z-index: 12;
        transition: all .2s ease;
      }

      img {
        position: relative;
        z-index: 11;

        @include mq(sp) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: self-start;

      @include mq(pc) {
        padding: 20px 30px;
        flex-grow: 1;
      }

      @include mq(sp) {
        padding: 20px;
      }
    }

    &__date {
      @include font-size(12, 21);
      opacity: .7;
    }

    &__title {
      @include font-size(14, 24);
      margin: .5em 0;
      display: -webkit-box;
      box-orient: vertical;
      overflow: hidden;
      width: 100%;

      @include mq(pc) {
        line-clamp: 2;
      }

      @include mq(sp) {
        line-clamp: 3;
      }
    }

    &__subtitle {
      @include font-size(12, 21);
      font-weight: 300;
      margin: .5em 0;
      display: -webkit-box;
      box-orient: vertical;
      overflow: hidden;
      width: 100%;
    }

    &__action {
      display: flex;
      align-items: center;
      padding-right: 45px;

      @include mq(sp) {
        justify-content: flex-end;
        padding-bottom: 20px;
        padding-right: 20px;
      }

      .cls-1 {
        fill: #fff;
        stroke-width: 0px;
        transition: all .3s;
      }

      .cls-2 {
        fill: none;
        stroke: #0046a0;
        stroke-miterlimit: 10;
        transition: all .3s;
      }
    }

    &:hover {
      .cls-1 {
        fill: #323232;
      }

      .cls-2 {
        stroke: white;
      }
    }
  }
}
