body.page-message {
    #case__swiper {
        @include case__swiper;
    }

    .mv {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include mq(pc) {
            height: 740px;
        }
        @include mq(sp) {
            height: 400px;
            margin-bottom: 300px;
        }
        &__bg {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &__content {
            background-color: white;
            @include mq(pc) {
                position: absolute;
                left: 0;
                bottom: 60px;
                width: 50%;
                padding-left: calc(50% - 600px);
                padding-right: 100px;
                padding-top: 40px;
                padding-bottom: 40px;
            }
            @include mq(sp) {
                width: calc(100% - 30px);
                padding: 30px;
                position: absolute;
                top: 100%;
                transform: translateY(-80px);
            }
        }

        &__heading {
            @include h3;
        }
        &__title {
            @include h2;
            margin-top: 1em;
        }
        &__div {
            @include alnum;
            @include font-size(14, 21);
            margin-top: 1.5em;
        }
        &__name {
            @include font-size(20, 24);
            margin-top: 0.6em;
        }
        &__position {
            @include font-size(14, 24);
            margin-top: 2.5em;
            color: $fg-gray;
            position: relative;
        }
    }
    .is-format {
        //@include has-wave-after;
        background: $bg-gradient-gray;
        @include mq(pc) {
            padding-top: 50px;
        }
        @include mq(sp) {
            padding-top: 40px;
        }
    }
    .career {
        position: relative;
        @include mq(pc) {
            padding: 60px 0 50px;
            margin: 70px 0 80px;
        }
        @include mq(sp) {
            padding: 30px 0 20px;
            margin: 60px 0 80px;
        }
        &::before {
            content: "";
            position: absolute;
            left: 4em;
            top: 0;
            right: 0;
            height: 2px;
            background: $bg-gradient;
        }
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: 2px;
            background: $bg-gradient;
        }
        &__title {
            position: absolute;
            left: 0;
            top: -0.5em;
            @include mq(pc) {
                @include font-size(20, 24);
            }
            @include mq(sp) {
                @include font-size(20, 24);
            }
        }
    }

    .links {
        @include has-wave-before;
    }

    .message {
        @include has-wave-before;
        @include has-wave-after;
        background: $bg-gradient-gray;
        &::after {
            z-index: 1;
            @include mq(sp) {
                bottom: -60px;
            }
        }
        &:last-of-type {
            background: linear-gradient(0deg, rgba(243,243,243,1) 0%, rgba(245,245,245,1) 100%);
            &::after {
                content: none;
            }
        }
        .section__title {
            text-align: center;
            &::before {
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .section__contents {
            margin-top: 50px;
            position: relative;
            z-index: 1;
        }

        h3 {
            margin-top: 2em;
            @include h3;
        }

        p {
            @include text;
        }

        &__lower {
            @include mq(pc) {
                margin-top: 100px;
            }
            @include mq(sp) {
                margin-top: 50px;
            }
        }
    }

    .band {
        background-color: white;

        @include mq(pc) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        @include mq(sp) {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr auto;
        }

        &__content {
            max-width: 650px;
            padding-left: 50px;
            padding-right: 100px;
            align-self: center;
            justify-self: flex-end;

            @include mq(pc) {
                padding-left: 50px;
                padding-right: 100px;
            }

            @include mq(sp) {
                padding: 50px 30px;
            }
        }
        &__title {
            @include h2;
        }
        &__position {
            @include font-size(14, 24);
            margin-top: 2.5em;
            color: $fg-gray;
            position: relative;
        }
        &__name {
            @include font-size(20, 24);
            margin-top: 0.6em;
        }
        &__div {
            @include alnum;
            @include font-size(14, 21);
            margin-top: 1em;
        }
    }
}
