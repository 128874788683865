body.page-talk {

	#menu {

		.menu__banners {
			margin-top: 30px;
			display: flex;
			flex-direction: column;
			row-gap: 10px;
		}

		.menu__banner {
			display: block;

			img {
				width: 100%;
			}
		}

		#menu__close--sp {
			display: none;
		}

		@include mq(sp) {
			border-radius: 0;

			html.menu-open & {
				grid-template-columns: 100px 1fr 42px;

				.is-show-on-open {
					display: block;
				}

				.is-show-on-close {
					display: none;
				}
			}

			html:not(.menu-open) & {
				grid-template-columns: 40px 175px 40px;
				gap: 40px;
				top: auto;
				bottom: 0;

				.is-show-on-open {
					display: none;
				}

				.is-show-on-close {
					display: block;
				}

				.is-totop {
					grid-column: 3 / 4;
					grid-row: 1 / 2;
				}

				.menu__control {
					grid-column: 1 / 2;
					grid-row: 1 / 2;
				}
			}
		}
	}

	@mixin recruit__section__label($label) {
		.section__title::after {
			content: $label;
			letter-spacing: normal;
			@include alnum;
			font-weight: bold;
			color: white;
			white-space: pre;
			text-align: right;
			position: absolute;
			z-index: -1;

			@include mq(pc) {
				@include font-size(100, 45, 40);
				right: -150px;
				bottom: 0;
			}

			@include mq(sp) {
				@include font-size(60, 35, 25);
				right: -30px;
				bottom: 1.5em;
			}
		}
	}

	.section.profile {
		padding-top: 55px;
		padding-bottom: 0;

		@include mq(sp) {
			padding-top: 30px;
		}
	}

	.section.talk {
		padding-top: 25px;
		padding-bottom: 40px;
	}

	.mv {
		height: 750px;
		position: relative;

		@include mq(sp) {
			height: 650px;
		}


		&__bg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&__header {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: 1;

			.menu {
				@include mq(sp) {
					background-color: transparent;
					display: flex;
					justify-content: center;
					gap: 20px;
				}

				&__name {
					color: white;

					@include mq(sp) {
						color: #323232;
					}
				}

				&__pagelink {
					color: white;
				}

				.icon {
					pointer-events: none;
					background-color: transparent;
					border-color: currentColor;
				}
			}
		}

		.container {
			height: 73%;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			@include mq(pc) {
				gap: 30px;
			}

			@include mq(sp) {
				gap: 30px;
			}
		}

		&__lead {
			@include font-size(35, 57);
			letter-spacing: .15em;
			font-weight: 700;
			margin-top: -30px;

			@include mq(sp) {
				@include font-size(25, 42);
				margin-right: -0.5em;
			}
		}

		&__title {
			width: 403px;

			@include mq(sp) {
				width: 315px;
				text-align: center;
				translate: 27px -19px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		&__watermark {
			@include alnum;
			font-weight: bold;
			color: white;
			mix-blend-mode: overlay;

			@include mq(pc) {
				@include font-size(100, 72);
				position: absolute;
				right: 0%;
				transform: translateX(150px);
				top: 140px;
			}

			@include mq(sp) {
				@include font-size(54, 60);
				margin-top: 1.0em;
				margin-left: auto;
				margin-right: auto;
			}
		}




	}

	.section {
		&__title {
			@include h1;

			+.section__desc {
				margin-top: 3em;
			}
		}

		&__subtitle {
			@include h3;
			@include heading_marker;

			@include mq(pc) {
				padding-top: 80px;
			}

			@include mq(sp) {
				padding-top: 40px;
			}
		}

		&__lead {
			@include h2;
		}

		&__desc {
			@include text;
			width: 100%;
		}
	}

	.career {
		position: relative;

		@include mq(pc) {
			padding: 65px 0 60px;
			margin: 85px 0 0;
		}

		@include mq(sp) {
			padding: 50px 0 45px;
			margin: 60px 0 8px;
		}

		&::before {
			content: "";
			position: absolute;
			left: 13.8em;
			top: 0;
			right: 0;
			height: 2px;
			background: $bg-gradient;
		}

		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			height: 2px;
			background: $bg-gradient;
		}

		.members {
			display: flex;
			justify-content: space-evenly;

			&._member-3 {
				justify-content: center;

				.member {
					flex: unset;
					flex-basis: 280px;

					@include mq(sp) {
						flex-basis: unset;
					}
				}
			}

			@include mq(sp) {
				flex-direction: column;
				gap: 20px;
			}
		}

		.member {
			flex-basis: 220px;
			flex: 1;
			padding-left: 30px;
			padding-right: 30px;

			@include mq(sp) {
				display: grid;
				grid-template-columns: 100px 1fr;
				column-gap: 30px;
				padding-left: 0;
				padding-right: 0;
			}

			&__img {
				width: 120px;
				height: 120px;
				margin-left: auto;
				margin-right: auto;

				@include mq(sp) {
					width: 100px;
					height: 100px;
				}
			}

			&__place {
				margin-top: 25px;
				color: #969696;
				letter-spacing: .05em;
				@include font-size(12, 21);

				@include mq(sp) {
					margin-top: 0;
				}
			}

			&__name {
				margin-top: 5px;
				letter-spacing: .05em;
				@include alnum;
				@include font-size(16, 19);
			}

			&__profile {
				margin-top: 15px;
				@include font-size(12, 21);
				letter-spacing: .15em;
			}

		}

		&__title {
			position: absolute;
			left: 0;
			top: -0.5em;

			@include mq(pc) {
				@include font-size(20, 24);
			}

			@include mq(sp) {
				@include font-size(20, 24);
			}
		}
	}

	.question {
		margin: 6em 0 3em;
		position: relative;
		@include font-size(20, 32);
		letter-spacing: .15em;
		display: block;
		color: transparent;
		background-clip: text;
		background-image: linear-gradient(to right, rgba(0, 190, 239, 1) 0%, rgba(0, 70, 160, 1) 100%);
		align-self: flex-start;
		justify-self: flex-start;

		@include mq(sp) {
			@include font-size(18, 30);
			margin: 3.5em 0 1.2em;
		}

		&::before {
			color: #fff;
			content: "Q";
			font-family: Alnum, sans-serif;
			font-weight: 700;
			left: -50px;
			letter-spacing: 0;
			position: absolute;
			z-index: -1;
			top: -0.7em;
			font-size: 6.5em;

			@include mq(sp) {
				left: -10px;
				top: -0.6em;
				font-size: 4em;
			}
		}

		&._no-icon {
			&::before {
				content: none;
			}
		}

	}

	.gradientBanner {
		border-radius: 10px;
		// box-shadow: -4px -4px 4px #fff, 4px 4px 6px #ccc;
		position: relative;

		&__text {
			color: #fff;
			position: absolute;
			text-align: center;
			left: 50%;
			top: 50%;
			width: 100%;
			transform: translate3d(-50%, -50%, 0);
			letter-spacing: .15em;
			line-height: 1.4;
			font-size: 30px;
			margin-top: 0;
			padding-left: 30px;
			padding-right: 30px;

			@include mq(pc) {
				@include font-size(30, 42);
			}

			@include mq(sp) {
				@include font-size(20, 35);
			}
		}
	}

	.interview {
		margin: 2em 0;
		display: flex;
		gap: 40px;

		+.image {
			@include mq(sp) {
				margin-top: 60px;
			}
		}

		@include mq(sp) {
			gap: 15px;
			margin: 0.8em 0;
		}

		&__icon {
			object-fit: contain;
			width: 70px;
			flex-shrink: 0;
			align-self: flex-start;
			margin-top: 30px;

			@include mq(sp) {
				width: 50px;
			}
		}

		&__name {
			@include font-size(16, 19);
			letter-spacing: .05em;
			@include alnum;
			margin-top: 10px;
		}

		&__body {
			margin-top: 15px;
			flex: 1;
			@include text;
			letter-spacing: .15em;

			@include mq(sp) {
				margin-top: 20px;
			}
		}

		&__text {
			@include mq(sp) {
				letter-spacing: .15em;
				font-size: 14px;
			}
		}
	}

	.pl-interview {
		@include mq(pc) {
			padding-left: 110px;
		}

		@include mq(sp) {
			padding-left: 65px;
			letter-spacing: 0.15em;
			font-size: 14px;
		}
	}

	.row {


		@include mq(pc) {
			display: grid;
			gap: 0 60px;
			grid-template-columns: 3fr 2fr;
			grid-template-rows: auto 1fr;
			margin: 2em 0;


			.image {
				margin: 0;
				grid-column: 2/3;
				margin-top: 20px;
				grid-row: 1/2;
			}

			.content {
				margin: 0 !important;
				grid-column: 1/2;
				grid-row: 1/2;
			}

		}
	}

	.rows {
		&._align-center {
			align-items: center;
		}

		&:has(.gradientBanner) {
			margin: 12em 0 2em;

			@include mq(sp) {
				margin: 4em 0 2em;
				margin-left: calc(-50vw + 50%);
				margin-right: calc(-50vw + 50%);
			}
		}

		@include mq(sp) {
			margin: 2em 0;
		}

		@include mq(pc) {
			display: flex;
			flex-direction: column;
			gap: 40px;
			margin: 4em 0 2em;

			.row {
				margin: 0;

				&.is-even,
				&:nth-child(even) {
					.image {
						grid-column: 1/2;
						grid-row: 1/2;
					}

					p {
						grid-column: 2/3;
						grid-row: 1/2;
					}

					h3,
					h4 {
						margin-top: 0;
						grid-column: 2/3;
						grid-row: 1/2;

						+.image {
							grid-column: 1/2;
							grid-row: 1/3;

							+p {
								grid-column: 2/3;
								grid-row: 2/3;
							}
						}
					}
				}
			}
		}
	}

	.message {
		position: relative;

		&__heading {
			@include font-size(20, 21);
			letter-spacing: .15em;

			@include mq(sp) {
				line-height: 1.45;
				font-size: 18px;
			}
		}

		&__text {
			margin-top: 40px;
			@include text;
			letter-spacing: .15em;

			@include mq(sp) {
				margin-top: 25px;
			}
		}

		&__content {
			width: 700px;
			position: absolute;
			left: 0;
			bottom: 0;
			padding: 65px 100px 60px;
			background-color: #fff;

			@include mq(sp) {
				width: 100%;
				position: static;
				padding: 50px 30px 50px;
				font-size: 14px;
			}
		}
	}

	.lead {
		position: relative;

		&__img {
			max-height: 600px;
			width: 100%;
			object-fit: cover;
			object-position: top;
		}


		&__content {
			padding-top: 110px;
			padding-bottom: 110px;
			position: absolute;
			left: 0;
			bottom: 0;

			@include mq(pc) {
				padding-top: 110px;
				padding-bottom: 110px;
				width: 490px;
				position: absolute;
				left: 0;
				bottom: 0;
			}

			@include mq(sp) {
				padding-top: 70px;
				padding-bottom: 70px;
				width: calc(100% - 60px);
				position: absolute;
				left: 30px;
				bottom: 0;
			}
		}

		&__text {
			@include text;
			color: white;

			@include mq(sp) {
				font-size: 14px;
			}
		}

		&__button {
			margin-top: 40px;
			display: grid;

			@include mq(pc) {
				place-items: center;
			}
		}
	}


	#recruitment {
		background: $bg-gradient-gray;
		@include recruit__section__label('Recruitment');

		.banners {
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;

			@include mq(pc) {
				background-image: url('../img/design/recruit_recruitment_bg.jpg');
				margin: 60px 0 40px;
			}

			@include mq(sp) {
				background-image: url('../img/design/recruit_recruitment_bg_sp.jpg');
				margin-top: 40px;
			}

			.container {
				display: flex;
				padding: 70px 0;

				@include mq(pc) {
					gap: 100px;
					align-items: center;
					justify-content: center;
				}

				@include mq(sp) {
					flex-direction: column;
					gap: 20px;
					align-items: center;
				}
			}

			.banner {
				border-radius: $border-radius;
				overflow: hidden;

				@include mq(pc) {
					max-width: 400px;
				}

				@include mq(sp) {}
			}
		}

		.recruit {
			&__group {
				@include alnum;
				@include font-size(20, 19);
				color: $fg-gray;
				margin: 3em 0 1.5em;
			}

			&__links {
				display: flex;

				@include mq(pc) {
					gap: 20px 40px;
					flex-wrap: wrap;
				}

				@include mq(sp) {
					gap: 20px;
					flex-direction: column;
				}
			}

			&__link {
				@include button-appearance('bevel-outside', $border-radius);
				@include headingtext(46, hover);
				padding: 15px 25px;

				@include mq(pc) {
					width: 530px;
					height: 80px;
				}

				@include mq(sp) {
					height: 100px;
				}

				.icon {
					position: absolute;
					right: 25px;
				}
			}
		}
	}


}
