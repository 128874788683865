body.page-supplier {
    @include mv-wave("../img/design/company_bg.jpg");

    main {
        background: $bg-gradient-gray;
    }

    .link{
        @include textlink;
    }

    .section:last-child {
        @include mq(pc) {
            padding-bottom: 160px;
        }
        @include mq(sp) {
            padding-bottom: 80px;
        }
    }

    .section__content {
        margin-top: 30px;

        .download {
            @include headingtext(46, hover);
            @include textlink;
        }

        .exlink {
            @include headingtext(46, hover);
            @include textlink;
            word-break: break-all;
        }

        .h3 {
            @include h3;
        }

        p {
            @include text;
        }
    }

    .index {
        .container {
            display: flex;
            @include mq(pc) {
                gap: 70px;
            }
            @include mq(sp) {
                flex-direction: column;
                gap: 30px;
            }
        }
    }

    .supplierButton {
        flex: 1;
        justify-content: space-between;
        box-shadow: $bevel-outside;
        border-radius: $border-radius;
        overflow: hidden;
        display: flex;
        &__content {
            display: flex;
            flex-direction: column;
            align-items: self-start;
            @include mq(pc) {
                padding: 20px 30px;
            }
            @include mq(sp) {
                padding: 20px;
            }
        }
        &__title {
            @include font-size(14, 24);
            margin: 0.5em 0;
            display: -webkit-box;
            box-orient: vertical;
            overflow: hidden;
            width: 100%;
            @include mq(pc) {
                line-clamp: 2;
            }
            @include mq(sp) {
                line-clamp: 3;
            }
        }
        &__category {
            display: flex;
            gap: 10px;
            letter-spacing: 0;
            @include mq(sp) {
                margin-top: 0.3em;
            }
            div {
                @include font-size(12, 21);
                border: 1px solid $border;
                border-radius: 1.1em;
                background-color: $white;
                color: $fg-gray;
                padding: 0.2em 1em;
                &:first-child {
                    background-color: $black;
                    color: white;
                }
            }
        }
        &__action {
            display: flex;
            align-items: center;
            padding-right: 20px;
        }
    }
}
