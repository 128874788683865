body.page-en_profile {
	@include mv-wave('../img/design/company_bg.jpg'); 

	.ov {
		padding-bottom: 0;
		.section {
			&__content {
			  @include mq(pc) {
				display: grid;
				column-gap: 95px;
				row-gap: 15px;
				margin-top: 70px;
				grid-template-columns: 1fr 500px;
				grid-template-rows: auto 1fr;
			  } 
			  @include mq(sp) { 
				.section__subtitle {
				  margin-top: 3.5em;
				}
			  }      
			}
			&__subtitle {
			  @include mq(sp) { 
				margin-bottom: 1em;
			  }
			}
			&__title {
			  @include mq(pc) {
				grid-column: 1/2;
				grid-row: 1/2;
			  }        
			}
			&__image {
			  @include mq(pc) {
				grid-column: 2/3;
				grid-row: 1/3;
			  } 
			  @include mq(sp) {
				margin: 1em 0 -4.5em;
			  }        
			}
			&__desc {
			  @include mq(pc) {
				grid-column: 1/2;
				grid-row: 2/3;
			  }        
			}
		  }
	}

	.feature {
		position: relative;
		background-repeat: no-repeat, no-repeat;
		@include mq(pc) {
		  padding-top: 250px;
		  padding-bottom: 100px;
		  background-image: url(../img/design/home_mv_feature_illust.svg), url(../img/design/home_mv_feature_bg.png);
		  background-size: 654px 546px, 2172px 807px;
		  background-position: calc(50% + 300px) calc(100% - 20px), calc(50% + 180px) 70px;
		}
		@include mq(sp) {
		  padding-top: 80px;
		  background-image: url(../img/design/home_mv_feature_illust.svg), url(../img/design/home_mv_feature_bg_sp.png);
		  background-size: 365px 305px, 887px 452px;
		  background-position: 30px 340px, calc(50% + 105px) 230px;
		}
		.bg {
		  position: absolute;
		  left: 50%;
		  transform: translateX(-50%);
		  width: 1400px;
		  height: 654.74px;
		}
		.title {
		  @include grad-text;
		  @include mq(pc) {
			@include font-size(40, 65);
		  }
		  @include mq(sp) {
			@include font-size(28, 48);
		  }
		}
		.desc {
		  @include font-size(20, 40);
		  @include mq(pc) {
			padding-top: 50px;
			width: 438px;
		  }
		  @include mq(sp) {
			padding-top: 440px;
			@include font-size(16, 30);
		  }
		}
		.button {
		  @include mq(pc) {
			margin-top: 60px;
		  }
		  @include mq(sp) {
			margin-top: 32px;
		  }     
		}
	  }



	.outline {
		.section__title{
			text-align: center;
			margin-bottom: 50px;
			&::before{
				transform: translateX(-50%);
				left: 50%;
			}
		}

		.googlemap{
			margin-top: 50px;
			width: 100%;
			height: 400px;
			@include mq(sp) {
			  height: 220px;
			 }
			> iframe{
			  width: 100%;
			  height: 100%;
			  max-width: 100%;
			}
		  }

		&__col {
		  .outline-table {
			width: 100%;
			> tbody {
			  display: flex;
			  flex-direction: column;
			  @include mq(pc) {
				@include text;
			  }
			  @include mq(sp) {
			   @include text--s;
			  }
			  tr {
				display: flex;
				margin: 0 0;
				width: 100%;
				border-top: 1px solid $border;
				border-left: 1px solid $border;
				border-right: 1px solid $border;
	
				&:last-of-type{
				  border-bottom: 1px solid $border;
				}
				@include mq(sp) {
				  flex-wrap: wrap;
				 }
			  }
			  th {
				padding: .8em 1.5em;
				background-color: white;
				flex-shrink: 0;
				text-align: left;
				font-weight: 500;
				@include mq(pc) {
				  width: 10em;
				}
				@include mq(sp) {
				  width: 100%;
				}
			  }
			  td {
				padding: .8em 1.5em;
				flex-grow: 1;
	
				@include mq(sp) {
				  width: 100%;
				  padding: .8em 1.5em;
				}
	
				.wrap-part{
				  @include mq(sp) {
				  display: block;
				  }
	
				  &:not(:first-of-type){
					@include mq(sp) {
					 padding-left: 1em;
					  }
				  }
				}
			  }
	
			  
			  .inner-table{
				width: 100%;
	
				> tbody {
				  @include text;
				  display: flex;
				  flex-direction: column;
				  tr {
					display: flex;
					width: 100%;
					border: none;
				  }
				  td:nth-of-type(1) {
					padding: 0 0;
					flex-shrink: 0;
					text-align: left;
					width: 12em;
					flex-grow: 0;
				  }
				  td:nth-of-type(2) {
					width: 65%;
					text-align: left;
					padding: 0 .8em;
					flex-grow: 1;
					@include mq(sp) {
					  width: 15%;
					  padding: 0 1.5em;
					}
				  }
			  }
	
			  &.inner-table--wrap{
				> tbody {
				  tr {
					@include mq(sp) {
					flex-wrap: wrap;
					}
				  }
				  td:nth-of-type(1) {
					@include mq(sp) {
					  width: 100%;
					}
				  }
				  td:nth-of-type(2) {
					@include mq(sp) {
					  width: 100%;
					  padding-left: 1em;
					}
				  }
			  }
		  }
		  }
	
			  
	

		  }
		}
	  }
	}
	
  }
