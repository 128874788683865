body.page-feature {
  #case__swiper {
    @include case__swiper;
  }
  @include mv-wave('../img/design/feature_bg.jpg', simple); 

  .items--ordered {
    flex-wrap: wrap;
    .item {
      @include mq(pc) {
        width: 320px;
      }      
    }
  }

  .ov {
    @include mq(pc) {
      padding: 0 0 50px;
    }
    @include mq(sp) {
      padding: 0
    }
    position: relative;
    &__image {
      @include mq(pc) {
        position: absolute;
        top: -215px;
        right: -85px;
        z-index: -1;
      }
      @include mq(sp) {
        overflow: hidden;
        margin: -55px -30px 0 -2px;
        img {
          width: 390px;
          max-width: none;
        }
      }
    }
    &__title {
      @include grad-text;
      letter-spacing: 0.1em;
      @include mq(pc) {
        @include font-size(40, 65);
      }
      @include mq(sp) {
        @include font-size(28, 48);
      }
    }
    &__desc {
      margin-top: 2em;
    }
  }
  #feature__swiper {
    @include mq(pc) {
      margin: 0 0 90px;
    }
    @include mq(sp) {
      margin: 60px 0;
    }
    .swiper-wrapper {
      transition-timing-function: linear;
    }
    .swiper-slide {
      width: auto;
    }
  }
  .detail {
    position: relative;
    @include mq(pc) {
      padding: 40px 0 10px;
      background-blend-mode: multiply;
      background-repeat: no-repeat;
      background-size:
        cover,
        720px 720px,
        720px 720px;
      background-image: 
        linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(241,241,241,1) 100%),
        radial-gradient(circle, rgba(225,225,225,1) 0%, rgba(255,255,255,1) 70%),
        radial-gradient(circle, rgba(225,225,225,1) 0%, rgba(255,255,255,1) 70%);
      background-position:
        left top,
        right -400px top -510px,
        -358px -45px;
      &::before {
        content: '';
        background-color: $bg;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 550px;
        clip-path: polygon(100% 0%, 0% 0% , 0.00% 100.00%, 2.86% 99.71%, 5.71% 98.84%, 8.57% 97.41%, 11.43% 95.43%, 14.29% 92.92%, 17.14% 89.92%, 20.00% 86.45%, 22.86% 82.56%, 25.71% 78.29%, 28.57% 73.69%, 31.43% 68.82%, 34.29% 63.73%, 37.14% 58.49%, 40.00% 53.14%, 42.86% 47.76%, 45.71% 42.40%, 48.57% 37.13%, 51.43% 32.01%, 54.29% 27.10%, 57.14% 22.46%, 60.00% 18.13%, 62.86% 14.17%, 65.71% 10.63%, 68.57% 7.54%, 71.43% 4.95%, 74.29% 2.88%, 77.14% 1.36%, 80.00% 0.39%, 82.86% 0.01%, 85.71% 0.20%, 88.57% 0.97%, 91.43% 2.31%, 94.29% 4.20%, 97.14% 6.62%, 100.00% 9.55%);
      }
    }
    @include mq(sp) {
      padding: 0;
      background-blend-mode: multiply;
      background-repeat: no-repeat;
      background-size:
        cover,
        192px 192px,
        192px 192px;
      background-image: 
        linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(241,241,241,1) 100%),
        radial-gradient(circle, rgba(225,225,225,1) 0%, rgba(255,255,255,1) 70%),
        radial-gradient(circle, rgba(225,225,225,1) 0%, rgba(255,255,255,1) 70%);
      background-position:
        left top,
        right -80px top 25px,
        -90px 170px;
      &::before{
        content: '';
        background-color: $bg;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 330px;
        clip-path: polygon(100% 0%, 0% 0% , 0.00% 100.00%, 4.00% 99.74%, 8.00% 98.97%, 12.00% 97.71%, 16.00% 95.99%, 20.00% 93.84%, 24.00% 91.31%, 28.00% 88.47%, 32.00% 85.37%, 36.00% 82.09%, 40.00% 78.70%, 44.00% 75.28%, 48.00% 71.90%, 52.00% 68.64%, 56.00% 65.58%, 60.00% 62.79%, 64.00% 60.32%, 68.00% 58.24%, 72.00% 56.59%, 76.00% 55.41%, 80.00% 54.72%, 84.00% 54.55%, 88.00% 54.90%, 92.00% 55.75%, 96.00% 57.09%, 100.00% 58.89%);
      }
    }
   .section {
      &__content {
        @include mq(pc) {
          display: grid;
          column-gap: 95px;
          row-gap: 15px;
          margin-top: 70px;
          grid-template-columns: 1fr 500px;
          grid-template-rows: auto 1fr;
        } 
        @include mq(sp) { 
          .section__subtitle {
            margin-top: 3.5em;
          }
        }      
      }
      &__subtitle {
        @include mq(sp) { 
          margin-bottom: 1em;
        }
      }
      &__title {
        @include mq(pc) {
          grid-column: 2/3;
          grid-row: 1/2;
        }        
      }
      &__image {
        @include mq(pc) {
          grid-column: 1/2;
          grid-row: 1/3;
        } 
        @include mq(sp) {
          margin: 1em 0 -4.5em;
        }        
      }
      &__desc {
        @include mq(pc) {
          grid-column: 2/3;
          grid-row: 2/3;
        }        
      }
    }
  }
  .e2e {    
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(241,241,241,1) 100%);
    background-position: left top;
    position: relative;
    @include mq(pc) {
      padding: 0 0 90px;
      &::before {
        content: '';
        background-color: $bg;
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 550px;
        clip-path: polygon(100% 100%, 0% 100% , 0.00% 90.96%, 2.86% 93.80%, 5.71% 96.14%, 8.57% 97.94%, 11.43% 99.19%, 14.29% 99.87%, 17.14% 99.97%, 20.00% 99.49%, 22.86% 98.44%, 25.71% 96.82%, 28.57% 94.66%, 31.43% 91.99%, 34.29% 88.83%, 37.14% 85.21%, 40.00% 81.19%, 42.86% 76.81%, 45.71% 72.12%, 48.57% 67.17%, 51.43% 62.02%, 54.29% 56.74%, 57.14% 51.37%, 60.00% 45.99%, 62.86% 40.66%, 65.71% 35.43%, 68.57% 30.37%, 71.43% 25.54%, 74.29% 21.00%, 77.14% 16.79%, 80.00% 12.96%, 82.86% 9.56%, 85.71% 6.64%, 88.57% 4.21%, 91.43% 2.32%, 94.29% 0.98%, 97.14% 0.20%, 100.00% 0.01%);
      }
    }
    @include mq(sp) {
      padding: 70px 0;
      &::before {
        content: '';
        background-color: $bg;
        position: absolute;
        bottom: -50px;
        left: 0;
        display: block;
        width: 100%;
        height: 150px;
        clip-path: polygon(100% 100%, 0% 100% , 0.00% 88.30%, 4.00% 92.70%, 8.00% 96.12%, 12.00% 98.50%, 16.00% 99.77%, 20.00% 99.92%, 24.00% 98.94%, 28.00% 96.84%, 32.00% 93.68%, 36.00% 89.53%, 40.00% 84.48%, 44.00% 78.65%, 48.00% 72.17%, 52.00% 65.19%, 56.00% 57.86%, 60.00% 50.35%, 64.00% 42.83%, 68.00% 35.48%, 72.00% 28.46%, 76.00% 21.92%, 80.00% 16.03%, 84.00% 10.90%, 88.00% 6.66%, 92.00% 3.41%, 96.00% 1.21%, 100.00% 0.12%);
      }
    }
    .ba {
      @include mq(pc) {
        display: flex;
        flex-direction: column-reverse;
      }
      &__desc {
        @include mq(pc) {
          margin: 2em 0 1em;
        }  
        @include mq(sp) {
          margin: 3em 0;
        }       
      }
      &__figure {
        text-align: center;
        @include mq(pc) {
          display: grid;
          grid-template-columns: 500px 1fr 500px; 
        }
      }
      &__before {
        @include mq(pc) {
          grid-column: 1/2;
          grid-row: 1/2;
        }
        &::before {
          content: 'Before';
          @include alnum;
          @include font-size(20, 60);
        }
        img {
          @include mq(sp) {
            width: 252px;
            height: 172px;
            margin: 20px 0 10px 15px;
          }          
        }
        .ba__item {
          color: $fg-gray;
        }
      } 
      &__after {
        color: $primary;
        @include mq(pc) {
          grid-column: 3/4;
          grid-row: 1/2;
        }
        &::before {
          content: 'After';
          @include alnum;
          @include font-size(20, 60);
        }
        img {
          @include mq(sp) {
            width: 292px;
            height: 158px;
            margin: 40px 0 30px 5px;
          }          
        }
      } 
      &__between {
        @include mq(pc) {
          grid-column: 2/3;
          grid-row: 1/2; 
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
        @include mq(sp) {
          margin: 40px 0 10px;
        }
        .dot {
          @include mq(pc) {
            margin-top: 20px;
            width: 100%;
            height: 70px;           
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 25px 5px;
            background-image: url(../img/design/dots--h.svg); 
          }
          @include mq(sp) {
            margin-top: 10px;
            width: 100%;
            height: 12px;           
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 18px 12px;
            background-image: url(../img/design/below.svg); 
          }
        }
      }
      &__title {
        @include font-size(14, 21);
        letter-spacing: -.5px;
      }
      &__image {
        display: flex;
        justify-content: center;
        @include mq(pc) {
          height: 217px;
        }
      }
      &__items {
        margin-top: 5px;
      } 
      &__item {
        @include font-size(14, 24);
        background-color: $white;
        border-radius: $border-radius;
        width: 100%;
        display: flex;
        align-items: center;
        text-align: left;
        @include mq(pc) {
          margin-top: 20px;
          height: 70px;
          padding: 0 2em;
        }
        @include mq(sp) {
          margin-top: 10px;
          padding: 1.2em 1.8em;
        }
      }
    }
  }
  .results {
    @include mq(pc) {
      padding: 40px 0;
    }
    @include mq(sp) {
      padding: 0 0 30px;
      &::before {
        content: '';
        background-repeat: no-repeat;
        background-size: cover;
        background-image: linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(241,241,241,1) 100%);
        background-position: left top;
        position: absolute;
        top: -100px;
        left: 0;
        display: block;
        width: 100%;
        height: 150px;
        clip-path: polygon(100% 0%, 0% 0% , 0.00% 88.30%, 4.00% 92.70%, 8.00% 96.12%, 12.00% 98.50%, 16.00% 99.77%, 20.00% 99.92%, 24.00% 98.94%, 28.00% 96.84%, 32.00% 93.68%, 36.00% 89.53%, 40.00% 84.48%, 44.00% 78.65%, 48.00% 72.17%, 52.00% 65.19%, 56.00% 57.86%, 60.00% 50.35%, 64.00% 42.83%, 68.00% 35.48%, 72.00% 28.46%, 76.00% 21.92%, 80.00% 16.03%, 84.00% 10.90%, 88.00% 6.66%, 92.00% 3.41%, 96.00% 1.21%, 100.00% 0.12%);
      }
    }
    .section {
      &__subtitle {
        @include mq(pc) {
          margin: 85px 0 25px;
        }
      }
    }
    .charts {
      @include mq(pc) {
        display: flex;
        justify-content: space-around;
        margin-left: -50px;
        margin-right: -50px;
        margin-top: 50px;
      }
      @include mq(sp) {
        margin-top: 30px;
      }
    }
    .chart {
      @include mq(pc) {
        width: 400px;
        padding: 0 50px;
        &:not(:first-child) {
          border-left: 1px solid $border;
          .chart__image {
            margin-top: 32px;
          }
        }
      }
      @include mq(sp) {
        padding: 30px 0;
        &:not(:first-child) {
          border-top: 1px solid $border;
          .chart__image {
            margin-top: 32px;
          }
        }
      }
      &__title {
        @include font-size(20, 30);
        &::before {
          content: '';
          display: block;
          margin-bottom: 20px;
          width: 20px;
          height: 3px;
          background-image: $bg-gradient;
        }       
      }
      &__image {
        margin-top: 10px;
      }
    }
    .history {
      @include mq(pc) {
        margin-bottom: -40px;
      }
      @include mq(sp) {
        padding: 30px 0;
      }
      &__item {
        display: grid;
        &:not(:first-child) {
          border-top: 1px solid $border;
        }
        @include mq(pc) {
          padding: 40px 0;
          grid-template-columns: 300px 1fr;
        }
        @include mq(sp) {
          padding: 30px 0;
          grid-template-columns: 90px 1fr;
        }
      }
      &__year {
        grid-column: 1/2;
        grid-row: 1/2;
        @include alnum;
        color: $primary;
        @include mq(pc) {
          @include font-size(20, 30);
        }
        @include mq(pc) {
          @include font-size(14, 30);
        }
      }
      &__title {
        grid-column: 2/3;
        grid-row: 1/2;
        @include mq(pc) {
          @include font-size(20, 36);
        }
        @include mq(sp) {
          @include font-size(14, 24);
        }
      }
      &__desc {
        grid-column: 2/3;
        grid-row: 2/3;
        @include mq(pc) {
          @include font-size(14, 26);
        }
        @include mq(sp) {
          margin-top: 1em;
          @include font-size(12, 21);
        }
      }
      &__image {
        margin-top: 1em;
        @include mq(pc) {
          grid-column: 2/3;
          grid-row: 3/4;
        }
        @include mq(sp) {
          grid-column: 1/3;
          grid-row: 3/4;
        }
      }
    }
    .global {
      @include mq(pc) {
        margin-top: 40px;
        display: grid;
        grid-template-columns: 407px 1fr;
        grid-template-rows: 33.3% 33.3% 33.3%;
        column-gap: 40px;
      }
      @include mq(sp) {
        padding: 40px 0;
      }
      &__image {
        @include mq(pc) {
          grid-column: 2/3;
          grid-row: 1/4;
        }
      }
      &__employee {
        padding-bottom: .7rem;
        strong {
          @include font-size(40, none, 20);
          @include alnum;
          font-weight: bold;
          color: $primary;
        }
        @include mq(pc) {
          @include font-size(20, 24);
          grid-column: 1/2;
          grid-row: 1/2;
          display: flex;
          align-items: center;
        }
      }
      &__center {
        padding: .7rem 0;
        border-top: 1px solid $border;
        @include mq(pc) {
          @include font-size(20, 24);
        }
        strong {
          @include font-size(40, none, 20);
          @include alnum;
          font-weight: bold;
          color: $primary;
        }
        @include mq(pc) {
          grid-column: 1/2;
          grid-row: 2/3;
          display: flex;
          align-items: center;
        }
      }
      &__revenue {
        padding: .7rem 0;
        border-top: 1px solid $border;
        @include mq(pc) {
          @include font-size(20, 24);
        }
        strong {
          @include alnum;
          @include font-size(40, none, 20);
          font-weight: bold;
          color: $secondary;
        }
        b {
          color: $secondary;
        }
        @include mq(pc) {
          grid-column: 1/2;
          grid-row: 3/4;
          display: flex;
          align-items: center;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 335px;
            border-top: 1px solid $border;
          }
        }
      }
    }
  }
  .solution {
    @include mq(pc) {
      padding-bottom: 40px;
    }
    @include mq(sp) {
      padding: 20px 0;
    }
    .section__content {
      @include mq(pc) {
        margin-top: 60px;
        display: flex;
        justify-content: center;
      }
      @include mq(sp) {
        margin-top: 30px;
      }
    }
    a {
      display: block;
      box-shadow: $bevel-outside;
      border-radius: $border-radius;
      overflow: hidden;
    }
  }
  .case {
    @include mq(pc) {
      padding-bottom: 125px;
    }   
  }
}