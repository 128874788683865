@mixin has-card__media {
  .card__media {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #e1e1e1;
      mix-blend-mode: multiply;
      z-index: 12;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
    }
  }
  &:hover {
    .card__media::after {
      opacity: 0;
    }
  }
}
@mixin Card {
  @include has-card__media;
  position: relative;
  background-color: $bg;
  box-shadow: $bevel-outside;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &__media {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 11;
    }
  }
  &__content {
    display: flex;
    gap: 1em;
    flex-direction: column;
    align-items: self-start;
    justify-content: flex-start;
    padding: 1.8em 1.5em;
  }
}
