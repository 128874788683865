/**
 * Fonts
*/
@font-face {
  font-family: 'Alnum';
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/Prompt-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Alnum';
  font-style: normal;
  font-weight: bold;
  src: url(../fonts/Prompt-Medium.ttf) format('truetype');
  font-display: swap;
}
/*
@font-face {
  font-family: 'JP';
  font-style: normal;
  font-weight: normal;
  src: 
    url(../fonts/NotoSansCJKjp-DemiLight.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp-DemiLight.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'JP';
  font-style: normal;
  font-weight: bold;
  src: 
    url(../fonts/NotoSansCJKjp-Bold.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp-Bold.woff) format('woff');
  font-display: swap;
}
*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap');