body.single-case_study {
  #case__swiper {
    @include case__swiper;
  }
  #info__swiper {
    .info {
      @include mq(pc) {
        &__card {
          width: 320px;
          height: 320px;
          &__category {
            margin-right: 0;
          }
        }
      }
    }
  }

  .company__info{
  .field__name{
    @include mq(sp){
      text-align: center;
    }
  }
}

  .mv {
    color: white;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    @include mq(pc) {
      min-height: 600px;
    }
    @include mq(sp) {
      min-height: 600px;
    }  
    .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      padding-top: 130px;
      padding-bottom: 130px;
      gap: 30px;
      @include mq(sp) {
        padding-top: 90px;
        padding-bottom: 90px;
      }
    }
    &__title {
   
      @include mq(pc) {
        @include font-size(30, 50);
      }
      @include mq(sp) {
        @include font-size(25, 43);
      }  
    }
    &__desc {
      @include mq(pc) {
        @include font-size(16, 30);
      }
      @include mq(sp) {
        @include font-size(14, 26);
      }  
    }
    .breadcrumb {
      color: white;
      margin-left: -50px;
    }
  }
  .company {
    margin: 0;
    display: grid;

   
    @include mq(pc) {
      transform: translateY(-50%);
      margin-bottom: -70px;
      grid-template-columns: 1fr 1fr;
      gap: 70px;
    }
    @include mq(sp) {
      transform: translateY(-50px);
      margin-bottom: -70px;
      gap: 30px;
    } 

    &.is-large{
      @include mq(pc) {
        transform: translateY(-50%);
     
      }
      @include mq(sp) {
        transform: translateY(-50px);
      } 
    }
    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2em;
      @include mq(pc) {
        grid-column: 1/2;
        grid-row: 1/2;
      }
      @include mq(sp) {
        grid-column: 1/2;
        grid-row: 1/2;
      } 
      .field {
        @include mq(sp) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
    &__service {
      position: relative;
      @include mq(pc) {
        padding: 0 40px 5px;
        grid-column: 2/3;
        grid-row: 1/2;
      }
      @include mq(sp) {
        grid-column: 1/2;
        grid-row: 2/3;
      } 
      &::before {
        content: '提供サービス';
        @include font-size(14, 24);
      }
    }
    &__categories {
      margin-top: .8em;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 4px;
    }
    &__category {
      @include service__category;
    }
    &__links {
      margin-top: 1.2em;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }
    &__link {
      @include service__link;
    }
  }
  .links,
  .info,
  .case {
    background: $bg-gradient-gray;
  }
  .is-format {
    background: $bg-gradient-gray;
    @include mq(pc) {
      padding-bottom: 80px;
    }
    @include mq(sp) {
      padding-bottom: 40px;
    }  

    h3.center{
      text-align: center;
    }
    h4 {
      padding-bottom: .5em;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        display: block;
        background: $bg-gradient;
      }
    }

    figure.caption-lower{
      padding: 0;
      border-radius: 0;

      figcaption{
        @include font-size(14, 24);
        letter-spacing: 0.15em;
        padding: 20px;
      }
    }

    .rows{
      figure {
        margin: 0;
      }

    }

    .rows.rows-1{
      .row{
        grid-template-rows: auto;

      }
    }

    .rows.is-margin-half{
        margin: 2em 0;
    }

    .row.is-reverse{
      .image {
        grid-column: 1/2;
        grid-row: 1/3;
      }
      p {
        grid-column: 2/3;
        grid-row: 1/2;
      }
      h3, h4 {
        margin-top: 0;
        grid-column: 2/3;
        grid-row: 1/2;
        + .image {
          grid-column: 1/2;
          grid-row: 1/3;
        }
        + p {
          grid-column: 2/3;
          grid-row: 2/3;
        } 
      }
    }

    .author__div{
      text-align: center;
    }
  }
}
