body.page-info {
  @include mv-wave('../img/design/feature_bg.jpg'); 

  .info {
    &__cards {
      @include card(460, 260, 270);
      display: flex;
      @include mq(pc) {
        flex-wrap: wrap;
        gap: 30px;
      }
      @include mq(sp) {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
  .pagination {
    @include mq(pc) {
      padding-top: 90px;
    }
    @include mq(sp) {
      padding-top: 40px;
    }      
  }
}
