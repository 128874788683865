body.page-thanks {
	@include mv-wave('../img/design/feature_bg.jpg'); 

	main {
		background: $bg-gradient-gray;
	  }
	.message{
		text-align: center;
		p{
			@include text;
		}

		.buttons{
			margin-top: 50px;
		}
	}
  }
