body.page-case_study {
  @include mv-wave('../img/design/feature_bg.jpg'); 

  main {
    background: $bg-gradient-gray;
  }

  .case {
    padding-top: 2em;
    @include mq(pc) {
      padding-bottom: 120px;
    }
    @include mq(sp) {
      padding-bottom: 60px;
    }
    &__cards {
      @include card(460, 260, 270);
      display: flex;
      @include mq(pc) {
        flex-wrap: wrap;
        gap: 70px;
      }
      @include mq(sp) {
        flex-direction: column;
        gap: 30px;
      }
    }
    .pagination {
      @include mq(pc) {
        padding-top: 90px;
      }
      @include mq(sp) {
        padding-top: 40px;
      }      
    }
  }
}
