body.home {
  .mv {
    background: linear-gradient(0deg, rgba($bg-dark,1) 0%, rgba(#f3f3f3,1) 15%, rgba($bg,1) 100%);

    @include mq(sp) {
      overflow: hidden;
      &::after {
        content: '';
        position: absolute;
        bottom: -280px;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 50px;
        display: block;
        width: 340px;
        height: 340px;
        mix-blend-mode:multiply;
        background: radial-gradient(#e1e1e1 0%, #fff 70%, #fff 100%);
      }
    }
    .firstview {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      position: relative;
      /*

      @include mq(pc) {
        background-image: url(../img/design/home_mv_firstview_bg.jpg); 
      }
      */
      @include mq(sp) {
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center top;
        background-image: url(../img/design/home_mv_bg_sp.jpg); 
      }
    }
    &__bg {
      position: absolute;
      left: 50%;
      top: 0;
      width: 100%;
      height: 906px;
      transform: translateX(-50%);
      svg {
        stroke: none;
      }
      .radialGradient-1 {
        z-index: -1;
        position: absolute;
        left: 1140px;
        top: 598px;
        transform: translate(-307px, -307px);
        display: block;
        width: 615px;
        height: 615px;
        background: #f5f5f5;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          mix-blend-mode:multiply;
          background: radial-gradient(#bebebe 0%, #fff 70%, #fff 100%);
        }
      }
      .radialGradient-2 {
        z-index: -1;
        position: absolute;
        left: 30%;
        top: 145px;
        transform: translate(-326px, -326px);
        display: block;
        width: 652px;
        height: 652px;
        background: #f5f5f5;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          mix-blend-mode:multiply;
          background: radial-gradient(#e1e1e1 0%, #fff 70%, #fff 100%);
        }
      }
    }
    &__obj {
      @include show-on-load(.5, 2, ease-in);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @include mq(pc) {
        margin-top: 35px;
        width: 1920px;
        height: 999px;
      }
      @include mq(sp) {
        width: 450px;
        height: 812px;
      }
      .line {
        stroke-dasharray: 2000px;
        stroke-dashoffset:2000px;
        animation: rect_anim 2s linear 2.8s forwards;
      }
      @keyframes rect_anim {
        0% {
          stroke-dashoffset:2000px;
        }
        100% {
          stroke-dashoffset:0px;
        }
      }
    }

    &__header {
      @include show-on-load;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
    &__lead {
      position: relative;
      svg {
        stroke-width: 0;
        @include mq(pc) {
          width: 227px;
          height: 100px;
          transform: translateX(.5em);
        }
        @include mq(sp) {
          width: 137.5px;
          height: 58.4px;
          transform: translateX(0);
        }
        width: 227px;
        height: 100px;
        &.back {
          position: absolute;
          left: 0;
          top: 0;
          color: rgba(255,255,255,.3);
        }
        &.front {
          position: relative;
          z-index: 1;
          color: $white;
        }
      }
      .line  {
        clip-path: inset(0 100% 0 0);
      }
      .is-loaded & .line {
        clip-path: inset(0 0 0 0);
        transition: clip-path .6s cubic-bezier(.43,.05,.17,1);
      }
      .back .line.a {
        transition-delay: 2.8s;
      }
      .back .line.b {
        transition-delay: 3.05s;
      }
      .front .line.a {
        transition-delay: 3.30s;
      }
      .front .line.b {
        transition-delay: 3.55s;
      }
    }
    &__scroll {
      @include show-on-load;
      @include scroll_anim;
      @include mq(pc) {
        margin-bottom: -60px;
      }
    }
    small {
      @include show-on-load;
      @include alnum;
      @include font-size(12);
      color: $white;
      margin-top: 5px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform-origin: center;
      transform: translate(-800px, -50%) rotate(90deg);
      text-shadow: 1px 0px 3px rgba(0,0,0,.1),1px 0px 3px rgba(0,0,0,.1);
      @include mq(pc) {
        margin-left: 35px;
      }
    }

    &__banners {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0;
      @include mq(pc) {
        margin-top: 90px;
      }
      &__title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        @include alnum;
        @include mq(pc) {
          @include font-size(25, 72);
        }
        @include mq(sp) {
          @include font-size(20, 72);
        }
        &::before {
          content: '';
          flex-shrink: 0;
          width: calc(50% - 4em);
          height: 0;
          border-bottom: 1px solid $border;
        }
        &::after {
          content: '';
          flex-shrink: 0;
          width: calc(50% - 4em);
          height: 0;
          border-bottom: 1px solid $border;
        }
      }
      &__items {
        margin: 1em auto;
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: center;
        @include mq(sp) {
          flex-direction: column;
        }
        img {
          box-shadow: $bevel-outside;
          border-radius: $border-radius;
          @include mq(pc) {
            width: 530px;
            height: 320px;
          }
          @include mq(sp) {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    &__overview {
      position: relative;
      @include mq(pc) {
      }
      @include mq(sp) {
        padding-top: 115px;
        overflow: hidden;
      }
      &__bg {
        overflow: hidden;
        position: absolute;
        @include mq(pc) {
          width: 100%;
          height: 654.74px;

          left: 50%;
          transform: translateX(-50%);
          .radialGradient {
            z-index: -1;
            position: absolute;
            left: 393px;
            top: 413px;
            transform: translate(-297px, -297px);
            display: block;
            width: 594px;
            height: 594px;
            background: #f4f4f4;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              mix-blend-mode:multiply;
              background: radial-gradient(#e1e1e1 0%, #fff 70%, #fff 100%);
            }
          }
        }
        @include mq(sp) {
          width: 615px;
          height: 306px;
          left: 0;
          top: 50px;
          .radialGradient {
            z-index: -1;
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 340px;
            height: 340px;
            background: #f4f4f4;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              mix-blend-mode:multiply;
              background: radial-gradient(#e1e1e1 0%, #fff 70%, #fff 100%);
            }
          }
        }
      }
      &__obj {
        position: absolute;
        @include mq(pc) {
          width: 1920px;
          height: 766px;
          left: 50%;
          transform: translateX(-50%);
        }
        @include mq(sp) {
          width: 832px;
          height: 422px;
          right: 0;
          transform: translateX(83px);
        }
      }
      .title {
        color: $white;
        @include mq(pc) {
          padding-top: 415px;
          @include font-size(40, 65);
        }
        @include mq(sp) {
          padding-top: 90px;
          @include font-size(28, 48);
        }
      }
      .desc {
        @include mq(pc) {
          @include font-size(20, 40);
          margin-left: 50%;
          padding-top: 60px;
          width: 550px;
          display: flex;
          justify-content: flex-end;
        }
        @include mq(sp) {
          @include font-size(16, 30);
          padding-top: 180px;
          margin-right: -30px;
        }
      }
    }

    &__feature {
      position: relative;
      background-repeat: no-repeat, no-repeat;
      @include mq(pc) {
        padding-top: 250px;
        padding-bottom: 100px;
        background-image: url(../img/design/home_mv_feature_illust.svg), url(../img/design/home_mv_feature_bg.png);
        background-size: 654px 546px, 2172px 807px;
        background-position: calc(50% + 300px) calc(100% - 20px), calc(50% + 180px) 70px;
      }
      @include mq(sp) {
        padding-top: 80px;
        background-image: url(../img/design/home_mv_feature_illust.svg), url(../img/design/home_mv_feature_bg_sp.png);
        background-size: 365px 305px, 887px 452px;
        background-position: 30px 340px, calc(50% + 105px) 230px;
      }
      .bg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 1400px;
        height: 654.74px;
      }
      .title {
        @include grad-text;
        @include mq(pc) {
          @include font-size(40, 65);
        }
        @include mq(sp) {
          @include font-size(28, 48);
        }
      }
      .desc {
        @include font-size(20, 40);
        @include mq(pc) {
          padding-top: 50px;
          width: 438px;
        }
        @include mq(sp) {
          padding-top: 440px;
          @include font-size(16, 30);
        }
      }
      .button {
        @include mq(pc) {
          margin-top: 60px;
        }
        @include mq(sp) {
          margin-top: 32px;
        }     
      }
    }
  }
}