body.page-recruitment {
  @include mq(sp) {
    padding-bottom: 100px;
  }

  .modal {
    &__close {
      cursor: pointer;
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url('../img/design/icon_modal_close.svg');

      @include mq(pc) {
        width: 40px;
        height: 40px;
        top: 50px;
        right: 50px;
      }

      @include mq(sp) {
        width: 30px;
        height: 30px;
        top: -40px;
        right: -10px;
      }
    }

    &__content {
      border-radius: $border-radius;
      overflow: hidden;
      background-color: white;
      display: flex;

      @include mq(pc) {
        flex-direction: row-reverse;
        width: 1100px;
        height: calc(100vh - 300px);
        max-height: 500px;

        &::after {
          width: 50%;
        }
      }

      @include mq(sp) {
        flex-direction: column;
        width: calc(100vw - 60px);
        height: 650px;
        max-height: calc(100vh - 160px);

        &::after {
          width: 100%;
        }
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2em;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    &__media {
      @include mq(pc) {
        width: 100%;
      }

      @include mq(sp) {
        height: 200px;
      }

      source,
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__text {
      overflow-y: scroll;
      position: relative;

      @include mq(pc) {
        width: 100%;
        padding: 50px;
      }

      @include mq(sp) {
        padding: 30px 20px;
      }
    }

    &__person {
      display: flex;
      flex-direction: column;

      @include mq(pc) {
        gap: .8em;
      }

      @include mq(sp) {
        gap: .6em;
      }
    }

    &__div {
      @include text--s;
      @include alnum;
      @include line-height(21);
      color: $fg-gray;
    }

    &__name {
      @include h4;
    }

    &__join {
      @include text--xs;
    }

    h5 {
      color: $primary;
    }

    .to-applicant {
      @include mq(pc) {
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: -50px;
        margin-right: -50px;
        padding: 50px;
      }

      @include mq(sp) {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: -20px;
        margin-right: -20px;
        padding: 30px 20px;
      }

      background-color: $gray;

      h5 {
        margin-top: 0;
      }
    }
  }

  #menu {

    .menu__banners {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    .menu__banner {
      display: block;

      img {
        width: 100%;
      }
    }

    #menu__close--sp {
      display: none;
    }

    @include mq(sp) {
      border-radius: 0;

      html.menu-open & {
        grid-template-columns: 100px 1fr 42px;

        .is-show-on-open {
          display: block;
        }

        .is-show-on-close {
          display: none;
        }
      }

      html:not(.menu-open) & {
        grid-template-columns: 40px 175px 40px;
        gap: 40px;
        top: auto;
        bottom: 0;

        .is-show-on-open {
          display: none;
        }

        .is-show-on-close {
          display: block;
        }

        .is-totop {
          grid-column: 3 / 4;
          grid-row: 1 / 2;
        }

        .menu__control {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
        }
      }
    }
  }

  .mv {
    position: relative;

    height: 100vh;
    min-height: 812px;

    &__bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;

      .menu {
        @include mq(sp) {
          background-color: transparent;
          display: flex;
          justify-content: center;
          gap: 20px;
        }

        &__name {
          color: white;
        }

        &__pagelink {
          color: white;
        }

        .icon {
          pointer-events: none;
          background-color: transparent;
          border-color: currentColor;
        }
      }
    }

    .container {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 35px;

      @include mq(pc) {
        gap: 30px;
      }

      @include mq(sp) {
        gap: 20px;
        padding-top: 75px;
      }
    }

    &__lead {
      @include h3;
      color: white;
      letter-spacing: .15em;
    }

    &__title {
      @include h1;
      color: white;
      letter-spacing: .15em;

      @include mq(sp) {
        font-size: 30px;
        line-height: 1.5;
      }
    }

    &__watermark {
      @include alnum;
      font-weight: bold;
      color: white;
      mix-blend-mode: overlay;
      opacity: .5;

      @include mq(pc) {
        @include font-size(100, 72);
        position: absolute;
        right: 0%;
        transform: translateX(150px);
        bottom: 80px;
      }

      @include mq(sp) {
        @include font-size(54, 60);
        margin-top: .3em;
        letter-spacing: .02em;
        line-height: 1;
      }
    }

    &__banners {
      display: flex;
      gap: 10px;

      @include mq(pc) {
        margin-top: 10px;
      }

      @include mq(sp) {
        margin-top: 15px;
        flex-direction: column;
      }
    }

    &__scroll {
      @include scroll_anim(white, left);
      transform: translateX(-50%);
    }

    small {
      @include font-size(12);
      @include alnum;
      color: $white;
      margin-top: 5px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform-origin: center;
      transform: translate(-835px, -50%) rotate(90deg);
      text-shadow: 1px 0px 3px rgba(0, 0, 0, .1), 1px 0px 3px rgba(0, 0, 0, .1);

      @include mq(pc) {
        margin-left: 35px;
      }
    }
  }

  .section {
    &__title {
      @include h1;

      +.section__desc {
        margin-top: 3em;
      }
    }

    &__subtitle {
      @include h3;
      @include heading_marker;

      @include mq(pc) {
        padding-top: 80px;
      }

      @include mq(sp) {
        padding-top: 40px;
      }
    }

    &__lead {
      @include h2;
    }

    &__desc {
      @include text;
      width: 100%;
    }
  }

  @mixin recruit__section__label($label) {
    .section__title::after {
      content: $label;
      letter-spacing: normal;
      @include alnum;
      font-weight: bold;
      color: white;
      white-space: pre;
      text-align: right;
      position: absolute;
      z-index: -1;

      @include mq(pc) {
        @include font-size(100, 45, 40);
        right: -150px;
        bottom: 0;
      }

      @include mq(sp) {
        @include font-size(60, 35, 25);
        right: -30px;
        bottom: 1.5em;
      }
    }
  }

  #message {
    background: $bg-gradient-gray;
    padding-top: 100px;
    @include recruit__section__label('Message');

    .can-scroll {
      margin-bottom: 0;
    }

    @include mq(pc) {
      padding-bottom: 0;
    }

    .row {
      display: flex;

      @include mq(pc) {
        margin: 60px 0;
        gap: 100px;
      }

      @include mq(sp) {
        flex-direction: column;
        justify-content: space-evenly;
        margin: 30px 0;
        gap: 30px;
      }
    }

    .section {
      &__lead {
        @include h2;
        @include grad-text;
        width: 100%;
      }
    }
  }

  .video {
    margin: 60px auto;

    @include mq(pc) {
      max-width: 800px;
    }

    @include mq(sp) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .items--ordered {
    @include mq(pc) {
      .item {
        width: 100%;

        &__title {
          min-height: 5.5em;
        }

        &__image {
          margin-top: 0;
        }
      }
    }

    @include mq(sp) {
      .item {
        border: none;
        margin-top: 1em;
      }
    }
  }

  .job {
    background: $bg-gradient-gray;
    position: relative;

    &::before {
      content: '';
      background-color: $bg-dark;
      position: absolute;
      z-index: -2;
      left: 0;
      top: 0;
      width: 100%;
    }

    @include mq(pc) {
      @include recruit__section__label('Job Description');
      padding-top: 180px;

      &::before {
        height: 550px;
        clip-path: polygon(100% 0%, 0% 0%, 0.00% 0.19%, 4.00% 0.10%, 8.00% 1.14%, 12.00% 3.30%, 16.00% 6.51%, 20.00% 10.71%, 24.00% 15.80%, 28.00% 21.67%, 32.00% 28.18%, 36.00% 35.18%, 40.00% 42.52%, 44.00% 50.03%, 48.00% 57.55%, 52.00% 64.89%, 56.00% 71.89%, 60.00% 78.39%, 64.00% 84.25%, 68.00% 89.34%, 72.00% 93.53%, 76.00% 96.73%, 80.00% 98.87%, 84.00% 99.90%, 88.00% 99.80%, 92.00% 98.57%, 96.00% 96.24%, 100.00% 92.86%);
      }
    }

    @include mq(sp) {
      @include recruit__section__label('Job\A Description');
      padding-top: 170px;

      &::before {
        height: 200px;
        clip-path: polygon(100% 0%, 0% 0%, 0.00% 0.00%, 4.00% 0.25%, 8.00% 1.01%, 12.00% 2.26%, 16.00% 3.99%, 20.00% 6.18%, 24.00% 8.82%, 28.00% 11.88%, 32.00% 15.32%, 36.00% 19.11%, 40.00% 23.21%, 44.00% 27.58%, 48.00% 32.18%, 52.00% 36.96%, 56.00% 41.87%, 60.00% 46.86%, 64.00% 51.88%, 68.00% 56.89%, 72.00% 61.82%, 76.00% 66.64%, 80.00% 71.29%, 84.00% 75.72%, 88.00% 79.90%, 92.00% 83.77%, 96.00% 87.30%, 100.00% 90.45%);
      }
    }

    .rows {
      counter-reset: row;
      counter-reset: row;
      display: flex;
      flex-direction: column;

      @include mq(sp) {
        gap: 100px;
        margin-top: 100px;
      }

      @include mq(pc) {
        gap: 128px;
        margin-top: 128px;
      }

      .row {
        @include has-ordered-heading(row);

        @include mq(pc) {
          display: grid;
          gap: 0 100px;
          grid-template-columns: 1fr 1fr;
        }

        &__title {
          margin-bottom: 1.5em;

          @include mq(pc) {
            @include h2;
            grid-column: 1/2;
            grid-row: 1/2;
            white-space: nowrap;
          }

          @include mq(sp) {
            @include h1;
          }

          @include grad-text;
        }

        &__image {
          @include mq(pc) {
            grid-column: 2/3;
            grid-row: 1/3;
          }
        }

        &__desc {
          @include mq(pc) {
            grid-column: 1/2;
            grid-row: 2/3;
          }
        }

        &__lead {
          @include mq(pc) {}

          @include h4;

          @include mq(sp) {
            margin: 1em 0;
          }
        }

        p {
          @include text;
        }

        &:nth-child(even) {
          @include mq(pc) {
            &::before {
              @include mq(pc) {
                left: auto;
                right: calc(500px + 30px);
                transform: translateX(100%);
              }

              @include mq(sp) {}
            }

            .row {
              &__title {
                grid-column: 2/3;
                grid-row: 1/2;
              }

              &__image {
                grid-column: 1/2;
                grid-row: 1/3;
              }

              &__desc {
                grid-column: 2/3;
                grid-row: 2/3;
              }

              p {}
            }
          }
        }
      }
    }
  }

  .employ {
    background: $bg-gradient-gray;
    position: relative;

    &::before {
      content: '';
      background-color: $bg-dark;
      position: absolute;
      z-index: -2;
      left: 0;
      top: 0;
      width: 100%;
    }

    @include mq(pc) {
      padding-top: 180px;

      &::before {
        height: 550px;
        clip-path: polygon(100% 0%, 0% 0%, 0.00% 0.19%, 4.00% 0.10%, 8.00% 1.14%, 12.00% 3.30%, 16.00% 6.51%, 20.00% 10.71%, 24.00% 15.80%, 28.00% 21.67%, 32.00% 28.18%, 36.00% 35.18%, 40.00% 42.52%, 44.00% 50.03%, 48.00% 57.55%, 52.00% 64.89%, 56.00% 71.89%, 60.00% 78.39%, 64.00% 84.25%, 68.00% 89.34%, 72.00% 93.53%, 76.00% 96.73%, 80.00% 98.87%, 84.00% 99.90%, 88.00% 99.80%, 92.00% 98.57%, 96.00% 96.24%, 100.00% 92.86%);
      }
    }

    @include mq(sp) {
      padding-top: 170px;

      &::before {
        height: 200px;
        clip-path: polygon(100% 0%, 0% 0%, 0.00% 0.00%, 4.00% 0.25%, 8.00% 1.01%, 12.00% 2.26%, 16.00% 3.99%, 20.00% 6.18%, 24.00% 8.82%, 28.00% 11.88%, 32.00% 15.32%, 36.00% 19.11%, 40.00% 23.21%, 44.00% 27.58%, 48.00% 32.18%, 52.00% 36.96%, 56.00% 41.87%, 60.00% 46.86%, 64.00% 51.88%, 68.00% 56.89%, 72.00% 61.82%, 76.00% 66.64%, 80.00% 71.29%, 84.00% 75.72%, 88.00% 79.90%, 92.00% 83.77%, 96.00% 87.30%, 100.00% 90.45%);
      }
    }

    @include recruit__section__label('Employment\A Location');

    &__card {
      @include mq(pc) {
        width: 1100px;
        height: 420px;
      }

      @include mq(sp) {
        width: 315px;
        height: 240px;
      }
    }

    .section {
      &__lead {
        @include h2;
      }
    }
  }

  #messages {
    background: $bg-gradient-gray;
    position: relative;

    &::before {
      content: '';
      background-color: $bg-dark;
      position: absolute;
      z-index: -2;
      left: 0;
      top: 0;
      width: 100%;
    }

    @include mq(pc) {
      padding-top: 180px;

      &::before {
        height: 150px;
        clip-path: polygon(100% 0%, 0% 0%, 0.00% 32.90%, 4.00% 23.86%, 8.00% 15.87%, 12.00% 9.26%, 16.00% 4.29%, 20.00% 1.17%, 24.00% 0.01%, 28.00% 0.86%, 32.00% 3.69%, 36.00% 8.39%, 40.00% 14.77%, 44.00% 22.56%, 48.00% 31.46%, 52.00% 41.11%, 56.00% 51.12%, 60.00% 61.08%, 64.00% 70.59%, 68.00% 79.28%, 72.00% 86.78%, 76.00% 92.80%, 80.00% 97.10%, 84.00% 99.50%, 88.00% 99.91%, 92.00% 98.31%, 96.00% 94.75%, 100.00% 89.40%);
      }
    }

    @include mq(sp) {
      padding-top: 150px;

      &::before {
        height: 40px;
        clip-path: polygon(100% 0%, 0% 0%, 0.00% 95.32%, 6.67% 99.63%, 13.33% 99.08%, 20.00% 93.73%, 26.67% 84.10%, 33.33% 71.13%, 40.00% 56.09%, 46.67% 40.46%, 53.33% 25.76%, 60.00% 13.43%, 66.67% 4.68%, 73.33% 0.37%, 80.00% 0.92%, 86.67% 6.27%, 93.33% 15.90%, 100.00% 28.87%);
      }
    }

    @include recruit__section__label('Messages');

    &__swiper {
      @include case__swiper(480, 280, 240);

      .swiper-slide {
        cursor: pointer;

        * {
          pointer-events: none;
        }
      }
    }

    @include mq(sp) {
      .swiper {
        padding-bottom: 35px;
      }

      .swiper__navigation .is-nav {
        display: flex;
      }
    }

    .messages__banners {
      display: flex;
      flex-direction: column;

      @include mq(pc) {
        margin-top: calc(4em + 40px);
      }

      @include mq(sp) {
        margin-top: 4em;
        row-gap: 10px;
      }

    }

    .messages__banner {
      max-width: 100%;
      object-fit: contain;
      display: block;

      @include mq(pc) {
        margin-top: -40px;
      }
    }
  }

  #recruitment {
    background: $bg-gradient-gray;
    @include recruit__section__label('Recruitment');

    .banners {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @include mq(pc) {
        background-image: url('../img/design/recruit_recruitment_bg.jpg');
        margin: 60px 0 40px;
      }

      @include mq(sp) {
        background-image: url('../img/design/recruit_recruitment_bg_sp.jpg');
        margin-top: 40px;
      }

      .container {
        display: flex;
        padding: 70px 0;

        @include mq(pc) {
          gap: 100px;
          align-items: center;
          justify-content: center;
        }

        @include mq(sp) {
          flex-direction: column;
          gap: 20px;
          align-items: center;
        }
      }

      .banner {
        border-radius: $border-radius;
        overflow: hidden;

        @include mq(pc) {
          max-width: 400px;
        }

        @include mq(sp) {}
      }
    }

    .recruit {
      &__group {
        @include alnum;
        @include font-size(20, 19);
        color: $fg-gray;
        margin: 3em 0 1.5em;
      }

      &__links {
        display: flex;

        @include mq(pc) {
          gap: 20px 40px;
          flex-wrap: wrap;
        }

        @include mq(sp) {
          gap: 20px;
          flex-direction: column;
        }
      }

      &__link {
        @include button-appearance('bevel-outside', $border-radius);
        @include headingtext(46, hover);
        padding: 15px 25px;

        @include mq(pc) {
          width: 530px;
          height: 80px;
        }

        @include mq(sp) {
          height: 100px;
        }

        .icon {
          position: absolute;
          right: 25px;
        }
      }
    }
  }
}
