body.home {
.contact {
  background-color: $bg-dark;
  @include mq(pc) {
    padding: 100px 0;
  }
  @include mq(sp) {
    padding: 65px 0;
  }
  .section {
    &__header {
      @include mq(pc) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      @include mq(pc) {
        gap: 45px;
        .button {
          width: 320px;
          height: 150px;
        }
      }
      @include mq(sp) {
        margin-top: 50px;
        flex-direction: column;
        gap: 30px;
        .button {
          width: 100%;
          height: 150px;
        }
      }
    }
  }
}
}