@use "sass:math";

body.home {
#consultant__swiper {
  .swiper {
    padding: 60px 0;
  }
}
.consultant {
  .section {
    &__header {
      @include mq(pc) {
        display: flex;
        > * {
          flex: 1;
        }
        p {
          margin-top: 2em;
        }
      }
      @include mq(sp) {

      }
    }
  }
  &__card {
    box-shadow: $bevel-outside;
    border-radius: $border-radius;
    overflow: hidden;
    @include mq(pc) {
      width: 320px;
      height: 500px;
    }
    @include mq(sp) {
      height: 450px;
      flex-direction: column;
    }
    &:hover {
      &::after {
        opacity: 0;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $fg-gray-light;
      mix-blend-mode: multiply;
      z-index: 3;
      transition: all .2s ease;
    }
    &__media {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      img {

      }
    }
    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 1.5em 1em;
      &::before {
        content: '';
        position: absolute;
        bottom: -25px;
        left: 0;
        width: 100%;
        height: 260px;
        background-image: $bg-gradient;
        mix-blend-mode: multiply;  
        /* multiply の場合、skew では 左下が card の border-radius を超えて憑依jされてしまうため、clip-path にする
        transform: skew(0, -25deg);  
        transform-origin: right top;
        */
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 156px);
        z-index: 1;  
      }
      &::after {
        content: '';
        position: absolute;
        bottom: -133px;
        left: 0;
        width: 100%;
        height: 260px;
        background-color: $black;  
        transform: skew(0, -26deg);  
        transform-origin: right top;
        z-index: 2;   
      }
    }
    &__genre {
      @include font-size(12, 24);
      flex-grow: 1;
      &::before {
        content: '専門分野';
        display: block;
        margin-bottom: 1em;
        @include font-size(14, 24, 12);
      }
    }
    &__div{
      @include alnum;
      @include font-size(12, 19);
      color: $white;
      text-align: right;
      margin-bottom: 1em;
      position: relative;
      z-index: 4;
    }
    &__name {
      color: $white;
      text-align: right;
      z-index: 4;
    }
  }
}
}