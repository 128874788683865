body.page-consultants {
  @include mv-wave('../img/design/company_bg.jpg'); 

  .consultant {
    padding-top: 2em;
    @include mq(pc) {
      padding-bottom: 120px;
    }
    @include mq(sp) {
      padding-bottom: 60px;
    }
    &__cards {
      @include card(540, 280, 320);
      display: flex;
      @include mq(pc) {
        flex-wrap: wrap;
        gap: 70px;
      }
      @include mq(sp) {
        flex-direction: column;
        gap: 30px;
      }
    }
  }
  .recruit {
    @include mq(pc) {
      padding-bottom: 160px;
    }
    @include mq(sp) {
      padding-bottom: 80px;
    }      
  }
}
