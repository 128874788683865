@mixin h1 {
  @include mq(pc) {
    @include font-size(40, 65);
  }

  @include mq(sp) {
    @include font-size(25, 43);
  }

  letter-spacing: .1em;
}

@mixin h2 {
  @include mq(pc) {
    @include font-size(30, 50);
  }

  @include mq(sp) {
    @include font-size(20, 35);
  }

  letter-spacing: .1em;
}

@mixin h3 {
  @include mq(pc) {
    @include font-size(25, 43);
  }

  @include mq(sp) {
    @include font-size(20, 35);
  }

  letter-spacing: .1em;
}

@mixin h4 {
  @include font-size(20, 35);
  letter-spacing: .1em;
}

@mixin h5 {
  @include font-size(18, 32);
  letter-spacing: .1em;
}

@mixin margin--heading {
  @include mq(pc) {
    margin: 1.6em 0;
  }

  @include mq(sp) {
    margin: 1em 0;
  }
}

@mixin text {
  @include font-size(16, 30);
}

@mixin text--s {
  @include font-size(14, 26);
}

@mixin text--xs {
  @include font-size(12, 21);
}

@mixin textlink {
  color: $primary;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

@mixin hover--w-b($selector) {
  &:not(:hover) {
    #{$selector} {
      background-color: white;
      color: $black;
    }
  }

  &:hover {
    #{$selector} {
      background-color: $black;
      color: white;
    }
  }
}

@mixin headingtext($iconSize: 28, $hover: hover) {
  @include text;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  @if hover==$hover {
    @include hover--w-b('span.icon');
  }

  &.is-exlink {
    .text::after {
      content: '';
      vertical-align: -.1em;
      margin-left: .5em;
      display: inline-block;
      width: #{math.div(30, 16)}em;
      height: #{math.div(14, 16)}em;
      background-repeat: no-repeat;
      background-position: center left;
      background-size: contain;
      background-image: url('../img/design/icon_link.svg')
    }
  }

  &.is-pdf {
    .text::after {
      content: '';
      vertical-align: -.1em;
      margin-left: .5em;
      display: inline-block;
      width: #{math.div(30, 16)}em;
      height: #{math.div(14, 16)}em;
      background-repeat: no-repeat;
      background-position: center left;
      background-size: contain;
      background-image: url('../img/design/icon_pdf.svg')
    }
  }

  &.is-xls {
    .text::after {
      content: '';
      vertical-align: -.1em;
      margin-left: .5em;
      display: inline-block;
      width: #{math.div(30, 16)}em;
      height: #{math.div(14, 16)}em;
      background-repeat: no-repeat;
      background-position: center left;
      background-size: contain;
      background-image: url('../img/design/icon_xls.svg')
    }
  }

  span.icon {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: #{$iconSize}px;
    height: #{$iconSize}px;
    border-radius: 50%;

    svg {
      width: 5px;
      height: 9px;
    }
  }
}

@mixin heading_marker {
  &::before {
    content: '';
    display: block;
    width: 50px;
    height: 6px;
    position: absolute;
    background-image: $bg-gradient;
    margin-top: -24px;
  }
}

@mixin btn($bevel: outside) {
  height: 60px;
  padding: 0 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease;

  @include mq(pc) {
    min-width: 200px;
  }

  @include mq(sp) {}

  &.is-close {
    background-color: $fg-gray;
    color: white;

    &:hover {
      background-color: $black;
    }
  }

  &.is-round {
    border-radius: 30px;
  }

  &.is-rect {
    border-radius: $border-radius;
  }
}

.btn {
  @include btn;
}

.breadcrumb {
  @include font-size(12, 21);
  color: $fg-gray;
  display: flex;
  gap: 1em;

  >*:not(:first-child)::before {
    content: '＞';
    margin-right: 1em;
  }
}

.pagelink {
  @include mq(pc) {
    margin: 60px 0;
  }

  @include mq(sp) {
    margin: 40px 0;
  }

  &__title {
    @include alnum;
    text-align: center;
    margin-bottom: 2em;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    margin-top: 20px;
  }
}

.video {
  aspect-ratio: 16 / 9;

  video,
  iframe {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@mixin chip($fontSize: none) {
  @if (none==$fontSize) {
    @include font-size(12, 21);
  }

  @else {
    @include font-size($fontSize);
  }

  border-radius: 1.1em;
  padding: .2em 1em;
  display: inline-flex;
  flex-grow: 0;

  &.is-light {
    border: 1px solid $border;
    background-color: white;
    color: $fg-gray;
  }

  &.is-dark {
    border: 1px solid $black;
    background-color: $black;
    color: white;
  }
}

.chip {
  @include chip;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq(pc) {
    gap: 80px;
  }

  @include mq(sp) {
    gap: 40px;
  }

  &__nav {
    box-shadow: $bevel-outside;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease;

    &:hover {
      background-color: black;
      color: white;
    }

    @include mq(pc) {
      width: 120px;
      height: 60px;
      border-radius: 30px;
    }

    @include mq(sp) {
      width: 80px;
      height: 40px;
      border-radius: 20px;
    }
  }

  &__pages {
    display: flex;
    gap: 9px;
  }

  &__page {
    @include alnum;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    ;
    background-color: white;
    transition: all .2s ease;

    &.is-current,
    &:hover {
      background-color: black;
      color: white;
    }
  }

  &__hellip {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
  }
}

.text {
  @include text;
}

.s {
  @include text--s;
}

.xs {
  @include text--xs;
}

.l {
  @include font-size(18, 32);
}

.mt {
  margin-top: 1.5em;
}

.mb {
  margin-bottom: 1.5em;
}

.is-format {
  >*:last-child {
    margin-bottom: 0 !important;
  }

  .h1,
  h1 {
    @include h1;
    @include margin--heading;
  }

  .h2,
  h2 {
    @include h2;
    @include margin--heading;
  }

  .h3,
  h3 {
    @include h3;
    @include margin--heading;
  }

  .h4,
  h4 {
    @include h4;
    @include margin--heading;
  }

  .h5,
  h5 {
    @include h5;
    @include margin--heading;
  }

  strong {
    font-weight: bold;
    background: transparent;
    background-image: linear-gradient(transparent 0% 70%, $secondary 70%);
    box-decoration-break: clone;
  }

  a.textlink {
    @include font-size(14, 24);
    @include textlink;
  }

  p {
    @include text;
  }

  .headingtexts {
    display: flex;

    @include mq(pc) {
      flex-wrap: wrap;
    }

    @include mq(sp) {
      flex-direction: column;
    }
  }

  .headingtext {
    @include headingtext;
    margin: 1.2em 0;
  }

  .h1,
  h1 {
    @include h1;
    @include margin--heading;
  }

  .h2,
  h2 {
    @include h2;
    @include margin--heading;
  }

  .h3,
  h3 {
    @include h3;
    @include margin--heading;
  }

  .h4,
  h4 {
    @include h4;
    @include margin--heading;
  }

  .h5,
  h5 {
    @include h5;
    @include margin--heading;
  }

  .image {
    @include mq(pc) {
      margin: 60px 0;
    }

    @include mq(sp) {
      margin: 30px 0;
    }

    &.is-16-9 {
      aspect-ratio: 16 / 9;
    }

    &.is-3-2 {
      aspect-ratio: 3 / 2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .paper {
    background: white;
    border-radius: $border-radius;
  }

  .block {
    @include mq(pc) {
      margin: 80px 0;
    }

    @include mq(sp) {
      margin: 30px 0;
    }
  }

  .box {
    @extend .paper;
    @extend .block;

    >*:first-child {
      margin-top: 0;
    }

    >*:last-child {
      margin-bottom: 0;
    }

    @include mq(pc) {
      padding: 30px 40px;
    }

    @include mq(sp) {
      padding: 30px;
    }
  }

  figure {
    @extend .paper;

    @include mq(pc) {
      margin: 60px 0;
      padding: 40px 100px;
    }

    @include mq(sp) {
      margin: 30px 0;
      padding: 30px 40px;
    }

    figcaption {
      @include h4;
    }

    .image {
      margin: 1em 0;
    }

    img {
      width: 100%;
    }
  }

  table {
    @include text;
    width: 100%;
    border-collapse: collapse;

    @include mq(pc) {
      margin: 60px 0;
    }

    @include mq(sp) {
      margin: 30px 0;

      tr {
        display: flex;
        flex-direction: column;
      }
    }

    tr {
      border-top: 1px solid $border;

      &:last-child {
        border-bottom: 1px solid $border;
      }
    }

    th {
      font-weight: bold;
      background-color: white;
      padding: .7em 1.5em;
      white-space: nowrap;
      text-align: left;

      @include mq(pc) {
        min-width: 150px;
      }
    }

    td {
      padding: .7em 1.5em;
    }
  }

  .can-scroll {
    @include mq(sp) {
      @include scroller(x);
      display: block;
      max-width: fit-content;
      white-space: nowrap;
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px;
      width: calc(100% + 60px);
    }
  }

  .pagelink {
    &__items {
      justify-content: center;
    }
  }

  .author {
    @extend .box;
    display: flex;

    @include mq(pc) {
      align-items: center;
      gap: 50px;
    }

    @include mq(sp) {
      flex-direction: column;
      gap: 30px;
    }

    &__image {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: .8em;
      flex-shrink: 0;
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: 1em;
    }

    &__div {
      @include alnum;
      @include font-size(12, 19);
    }

    &__name {
      @extend .xs;
    }
  }

  .row {
    @include mq(pc) {
      display: grid;
      gap: 0 60px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto 1fr;
      margin: 2em 0;

      .image {
        margin: 0;
        grid-column: 2/3;
        grid-row: 1/2;
      }

      p {
        margin: 0 !important;
        grid-column: 1/2;
        grid-row: 1/2;
      }

      h3,
      h4 {
        margin-top: 0;
        grid-column: 1/2;
        grid-row: 1/2;

        +p {
          grid-column: 1/2;
          grid-row: 2/3;

          +.image {
            grid-column: 2/3;
            grid-row: 1/3;
            align-self: flex-start;
          }
        }
      }
    }
  }

  .rows {
    @include mq(sp) {
      margin: 4em 0;
    }

    @include mq(pc) {
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin: 4em 0 2em;

      .row {
        margin: 0;

        &.is-even,
        &:nth-child(even) {
          .image {
            grid-column: 1/2;
            grid-row: 1/2;
          }

          p {
            grid-column: 2/3;
            grid-row: 1/2;
          }

          h3,
          h4 {
            margin-top: 0;
            grid-column: 2/3;
            grid-row: 1/2;

            +.image {
              grid-column: 1/2;
              grid-row: 1/3;

              +p {
                grid-column: 2/3;
                grid-row: 2/3;
              }
            }
          }
        }
      }
    }
  }

  ul {
    @include font-size(14, 26, none, rem);
    margin: 1em 0;
    padding-left: 1.5em;

    ul,
    ol {
      margin: 0;
    }

    li {
      text-indent: -1.5em;

      &::before {
        content: '●';
      }

      li {
        &::before {
          content: '・';
        }
      }
    }
  }

  ol {
    @include font-size(14, 26, none, rem);
    margin: 1em 0;
    padding-left: 1.5em;

    &.is-lower-latin {
      list-style-type: lower-latin;
    }

    ol,
    ul {
      margin: 0;
    }
  }

  .video {
    @extend .block;
    max-width: 560px;
  }

  .cta {
    box-shadow: $bevel-outside;
    border-radius: $border-radius;
    padding: 20px;
    position: relative;

    &:hover {
      a {
        text-decoration: none;
      }
    }


    a {

      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  .speech {
    display: flex;
    flex-direction: column;

    @include mq(pc) {
      gap: 30px;
      margin: 60px 0;
    }

    @include mq(sp) {
      flex-direction: column;
      gap: 20px;
      margin: 30px 0;
    }

    >* {
      background-color: white;
      border-radius: $border-radius;
      position: relative;

      @include mq(pc) {
        max-width: 520px;
        @include text;
        padding: 1em;
      }

      @include mq(sp) {
        @include text--s;
        padding: .6em 1em;
      }

      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 16px;
        position: absolute;
        top: 14px;
        background-color: inherit;

        @include mq(pc) {
          width: 20px;
          height: 16px;
        }

        @include mq(sp) {
          width: 15px;
          height: 12px;
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        background-repeat: no-repeat;

        @include mq(pc) {
          width: 50px;
          height: 106px;
          background-size: contain;
        }

        @include mq(sp) {
          background-color: #dddddd;
          width: 40px;
          height: 40px;
          background-size: 60px auto;
          clip-path: circle(50% at 50% 50%);
        }
      }
    }

    &__left {
      align-self: flex-start;

      @include mq(pc) {
        margin-left: 80px;
      }

      @include mq(sp) {
        margin-left: 60px;
      }

      &::before {
        @include mq(pc) {
          left: -20px;
        }

        @include mq(sp) {
          left: -15px;
        }

        clip-path: polygon(100% 0, 0 50%, 100% 100%);
      }

      &::after {
        @include mq(pc) {
          left: -80px;
        }

        @include mq(sp) {
          left: -60px;
        }

        background-position: -4px 5px;
        background-image: url('../img/design/format_speech_left.svg');
      }
    }

    &__right {
      align-self: flex-end;

      @include mq(pc) {
        margin-right: 80px;
      }

      @include mq(sp) {
        margin-right: 60px;
      }

      &::before {
        @include mq(pc) {
          right: -20px;
        }

        @include mq(sp) {
          right: -15px;
        }

        clip-path: polygon(0 0, 100% 50%, 0 100%);
      }

      &::after {
        @include mq(pc) {
          right: -80px;
        }

        @include mq(sp) {
          right: -60px;
        }

        background-position: -16px 5px;
        background-image: url('../img/design/format_speech_right.svg');
      }
    }
  }

  // .white-box {
  //   background-color: white;
  //   border: 1px solid #707070;
  //   border-radius: 10px;
  //   padding: 1.6em;
  //   position: relative;
  //   cursor: pointer;
  //   transition: all .2s;

  //   &:hover {
  //     opacity: .7;
  //   }

  //   @include mq(pc) {
  //     margin: 60px 0;
  //   }

  //   @include mq(sp) {
  //     margin: 30px 0;
  //   }

  //   &__text[class] {
  //     margin: 0;
  //     @include font-size(12, 21);
  //   }

  //   &__link {
  //     @include textlink;
  //     margin-top: 15px;
  //     @include font-size(14, 24);
  //     display: block;

  //     &:hover {
  //       text-decoration: underline;
  //     }

  //     &::after {
  //       position: absolute;
  //       content: '';
  //       width: 100%;
  //       height: 100%;
  //       left: 0;
  //       top: 0;
  //     }
  //   }
  // }
}

.buttons {
  display: flex;
  justify-content: center;

  &.is-left {
    justify-content: flex-start;
  }

  @include mq(pc) {
    gap: 30px;
    margin: 30px 0;
  }

  @include mq(sp) {
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
  }
}

@mixin grad-text {
  display: inline-block;
  color: transparent;
  background-clip: text;
  background-image: $bg-gradient-secondary;
}

@mixin has-ordered-heading($counter) {
  counter-increment: $counter;
  position: relative;

  &::before {
    content: "0" counter($counter);
    @include alnum;
    position: absolute;
    left: -30px;
    color: white;
    font-weight: bold;
    z-index: -1;

    @include mq(pc) {
      @include font-size(130);
      top: -.3em;
    }

    @include mq(sp) {
      @include font-size(100);
      top: -.4em;
    }
  }
}

/* Scroll animation on first view */
@mixin scroll_anim($color: #333, $pos: center) {
  position: absolute;
  bottom: 0;

  @if (center==$pos) {
    left: 50%;
    transform: translateX(-50%);
  }

  @else {
    left: 0;
  }

  &__text {
    @include font-size(12);
    @include alnum;
    color: $color;
    transform-origin: center;
    text-shadow: 1px 0px 3px rgba(255, 255, 255, .1), 1px 0px 3px rgba(255, 255, 255, .1);

    @include mq(pc) {
      transform: rotate(90deg) translate(1em, -1.5em);
    }

    @include mq(sp) {
      transform: rotate(90deg) translate(-50%, 0);
    }
  }

  &__anim {
    @include mq(pc) {
      height: 160px;
    }

    @include mq(sp) {
      height: 110px;
    }

    width: 2px;
    position: relative;
    background: transparent;
    animation: scroll_anim 1.5s ease infinite;
    border-radius: 100%;
    margin: 0 auto;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(transparent, $color, transparent);
    }
  }
}

@keyframes scroll_anim {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }

  45% {
    transform-origin: top;
    transform: scaleY(1);
  }

  55% {
    transform-origin: bottom;
    transform: scaleY(1);
  }

  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
}

/* Waves */
@mixin mv-wave($bg-url: none, $type: multi) {
  @if (multi==$type) {
    main {
      @include mq(pc) {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          right: 0;
          top: 125px;
          display: block;
          width: 1400px;
          height: 320px;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url(../img/design/bg_main_1.svg);
          background-position: center;
        }
      }
    }
  }

  .mv {
    @if (none==$bg-url) {
      position: relative;
    }

    @else {
      background-image: url($bg-url);
    }

    background-repeat: no-repeat;

    @include mq(pc) {
      height: 370px;
      background-size: 100%;
      background-position: center calc(100% + 0px);
      // https://wave.novoselski.net/
      clip-path: polygon(100% 0%, 0% 0%, 0.00% 79.61%, 5.00% 82.23%, 10.00% 84.18%, 15.00% 85.29%, 20.00% 85.48%, 25.00% 84.74%, 30.00% 83.12%, 35.00% 80.74%, 40.00% 77.81%, 45.00% 74.55%, 50.00% 71.22%, 55.00% 68.09%, 60.00% 65.40%, 65.00% 63.37%, 70.00% 62.15%, 75.00% 61.86%, 80.00% 62.50%, 85.00% 64.03%, 90.00% 66.33%, 95.00% 69.21%, 100.00% 72.45%);
    }

    @include mq(sp) {
      height: 230px;
      background-size: 175%;
      background-position: 0px calc(100% - 10px);
      // https://wave.novoselski.net/
      clip-path: polygon(100% 0%, 0% 0%, 0.00% 90.43%, 5.00% 91.90%, 10.00% 93.02%, 15.00% 93.70%, 20.00% 93.91%, 25.00% 93.63%, 30.00% 92.87%, 35.00% 91.69%, 40.00% 90.18%, 45.00% 88.44%, 50.00% 86.59%, 55.00% 84.77%, 60.00% 83.11%, 65.00% 81.71%, 70.00% 80.69%, 75.00% 80.12%, 80.00% 80.02%, 85.00% 80.42%, 90.00% 81.29%, 95.00% 82.55%, 100.00% 84.13%);
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 1em;
      height: 100%;

      @include mq(pc) {
        width: 1200px;
      }

      @include mq(sp) {}
    }

    &__title {
      letter-spacing: 0.18em;

      @include mq(pc) {
        @include font-size(30, 30);
        margin-top: 40px;
      }

      @include mq(sp) {
        @include font-size(18, 28);
        margin-top: 55px;
      }
    }
  }
}

@mixin has-wave-before {
  position: relative;
  padding-top: 0;
  background: $bg-gradient-gray;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    background-color: $bg-dark;
    clip-path: polygon(100% 0%, 0% 0%, 0.00% 28.87%, 4.00% 20.24%, 8.00% 12.82%, 12.00% 6.89%, 16.00% 2.70%, 20.00% 0.42%, 24.00% 0.13%, 28.00% 1.85%, 32.00% 5.51%, 36.00% 10.97%, 40.00% 17.99%, 44.00% 26.31%, 48.00% 35.58%, 52.00% 45.43%, 56.00% 55.47%, 60.00% 65.28%, 64.00% 74.48%, 68.00% 82.70%, 72.00% 89.59%, 76.00% 94.89%, 80.00% 98.39%, 84.00% 99.93%, 88.00% 99.46%, 92.00% 97.00%, 96.00% 92.64%, 100.00% 86.57%);

    @include mq(pc) {
      height: 150px;
      top: -90px;
    }

    @include mq(sp) {
      height: 60px;
      top: -50px;
    }
  }
}

.has-wave-before {
  @include has-wave-before;
}

@mixin has-wave-after {
  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    background-color: $bg;
    clip-path: polygon(100% 100%, 0% 100%, 0.00% 28.87%, 4.00% 20.24%, 8.00% 12.82%, 12.00% 6.89%, 16.00% 2.70%, 20.00% 0.42%, 24.00% 0.13%, 28.00% 1.85%, 32.00% 5.51%, 36.00% 10.97%, 40.00% 17.99%, 44.00% 26.31%, 48.00% 35.58%, 52.00% 45.43%, 56.00% 55.47%, 60.00% 65.28%, 64.00% 74.48%, 68.00% 82.70%, 72.00% 89.59%, 76.00% 94.89%, 80.00% 98.39%, 84.00% 99.93%, 88.00% 99.46%, 92.00% 97.00%, 96.00% 92.64%, 100.00% 86.57%);
  }

  @include mq(pc) {
    padding-bottom: 150px;

    &::after {
      height: 150px;
      bottom: -60px;
    }
  }

  @include mq(sp) {
    padding-bottom: 100px;

    &::after {
      height: 60px;
      bottom: -10px;
    }
  }
}

.has-wave-after {
  @include has-wave-after;
}

.buttons {
  display: flex;
  justify-content: center;

  @include mq(pc) {
    gap: 30px;
    margin: 30px 0;
  }

  @include mq(sp) {
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
  }
}

.is-bg-gradient-gary {
  background: $bg-gradient-gray;
}

.is-bg-gradient-primary {
  background: $bg-gradient;
}

@mixin watermark($text) {
  position: relative;

  &::before {
    content: '';
    background-color: rgba(0, 0, 0, .15);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &::after {
    content: '#{$text}';
    @include alnum;
    font-weight: bold;
    font-size: 3em;
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3)
  }
}

.is-sample {
  @include watermark(SAMPLE);
}

.is-wip {
  @include watermark(作成中);
}
