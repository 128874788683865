/**
 * Variables
*/

/* Typography */
//$font-family: 'Noto Sans CJK JP Subset', sans-serif;
$font-family: 'Noto Sans JP', sans-serif;
$font-family-alnum: 'Alnum', sans-serif;
$font-size: 16px;
$font-size-sp: 16px;
$font-size-pc: 16px;
$letter-spacing: .13em;

/* Breakpoints */
$breakpoints: (
    'sp'   : 'screen and (max-width: 599px)',
    'pc'   : 'screen and (min-width: 600px)',
);

/* Paths */
/* Grid setup */
/* Colour palette */
$gray: #f5f5f5;
$white: #ffffff;
$black: #323232;
$primary: #0046a0;
$secondary: #00BEFF;

$bg: #f5f5f5;
$bg-dark: #eeeeee;
$bg-overlay: #bebebe;
$bg-gradient: linear-gradient(142deg, rgba(0,70,160,1) 0%, rgba(0,190,239,1) 100%);
$bg-gradient-secondary: linear-gradient(135deg, rgba(0, 190, 255, 1) 0%, rgba(0, 70, 160, 1) 100%);
$bg-gradient-gray: linear-gradient(0deg, rgba(238,238,238,1) 0%, rgba(245,245,245,1) 100%);

$border: #e1e1e1;
$border-radius: 10px;

$fg-black: #323232;
$fg-gray: #969696;
$fg-gray-light: #e1e1e1;
$fg-white: #ffffff;
$fg-primary: #0046a0;

$button-base: #f5f5f5;
$button-hilight: #ffffff;
$button-shadow: #cccccc;

/* Components */
$bevel-outside: -4px -4px 4px $button-hilight, 4px 4px 6px $button-shadow;
$bevel-inside: inset -4px -4px 4px $button-hilight, inset 2px 2px 4px $button-shadow;