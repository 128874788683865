body.page-service {
  #case__swiper {
    @include case__swiper;
  }
  @include mv-wave('../img/design/service_bg.jpg'); 

  .ov {
    padding: 0;
  }
  .service {
    position: relative;
    background: $bg-gradient-gray;
    @include mq(pc) {
      padding-top: 60px;
      padding-bottom: 360px;
      &::before {
        height: 150px;
      }
    }
    @include mq(sp) {
      padding-top: 30px;
      padding-bottom: 150px;
      &::before {
        height: 75px;
      }
    }
    &::before {
      content: '';
      background-color: $bg;
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      clip-path: polygon(100% 100%, 0% 100% , 0.00% 14.64%, 5.00% 6.96%, 10.00% 1.99%, 15.00% 0.02%, 20.00% 1.20%, 25.00% 5.45%, 30.00% 12.49%, 35.00% 21.90%, 40.00% 33.06%, 45.00% 45.29%, 50.00% 57.82%, 55.00% 69.86%, 60.00% 80.65%, 65.00% 89.51%, 70.00% 95.89%, 75.00% 99.38%, 80.00% 99.78%, 85.00% 97.04%, 90.00% 91.35%, 95.00% 83.07%, 100.00% 72.70%);
    }
    &__card {
      @include mq(pc) {
        margin-top: 30px;
      }
      @include mq(sp) {
        margin-top: 40px;
        width: 100%;
      }
      &__media {
        @include mq(pc) {
          width: 260px;
        }     
      }
      &__title {
        @include mq(pc) {
          @include font-size(25, 50);
        }
        @include mq(pc) {
          @include font-size(20, 32);
        }
      }
    }
  }
  .links {
    background: $bg-gradient-gray;
    padding-top: 0;
  }
}

