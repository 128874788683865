body.home {
#case__swiper {
  @include case__swiper;
}
.case {
  background: linear-gradient(0deg, rgba($bg-dark,1) 0%, rgba($bg,1) 100%);
  @include mq(pc) {
    margin-top: -150px;
    padding-top: 110px;
    padding-bottom: 250px;
    // https://wave.novoselski.net/
    clip-path: polygon(100% 100%, 0% 100%,
      0.00% #{20.67 * 1.5}px,
      5.00% #{12.72 * 1.5}px,
      10.00% #{7.16 * 1.5}px,
      15.00% #{4.33 * 1.5}px,
      20.00% #{4.41 * 1.5}px,
      25.00% #{7.40 * 1.5}px,
      30.00% #{13.10 * 1.5}px,
      35.00% #{21.17 * 1.5}px,
      40.00% #{31.09 * 1.5}px,
      45.00% #{42.24 * 1.5}px,
      50.00% #{53.92 * 1.5}px,
      55.00% #{65.40 * 1.5}px,
      60.00% #{75.95 * 1.5}px,
      65.00% #{84.91 * 1.5}px,
      70.00% #{91.72 * 1.5}px,
      75.00% #{95.95 * 1.5}px,
      80.00% #{97.33 * 1.5}px,
      85.00% #{95.79 * 1.5}px,
      90.00% #{91.40 * 1.5}px, 
      95.00% #{84.46 * 1.5}px,
      100.00% #{75.40 * 1.5}px
    );
  }
  @include mq(sp) {
    margin-top: -60px;
    padding-top: 100px;
    padding-bottom: 160px;
    // https://wave.novoselski.net/
    clip-path: polygon(100% 100%, 0% 100%,
      0.00% #{4.71 * 0.6}px, 
      5.00% #{4.00 * 0.6}px, 
      10.00% #{4.95 * 0.6}px, 
      15.00% #{7.52 * 0.6}px, 
      20.00% #{11.62 * 0.6}px, 
      25.00% #{17.10 * 0.6}px, 
      30.00% #{23.77 * 0.6}px, 
      35.00% #{31.39 * 0.6}px, 
      40.00% #{39.69 * 0.6}px, 
      45.00% #{48.39 * 0.6}px, 
      50.00% #{57.16 * 0.6}px, 
      55.00% #{65.71 * 0.6}px, 
      60.00% #{73.72 * 0.6}px, 
      65.00% #{80.91 * 0.6}px, 
      70.00% #{87.04 * 0.6}px, 
      75.00% #{91.87 * 0.6}px, 
      80.00% #{95.25 * 0.6}px, 
      85.00% #{97.04 * 0.6}px, 
      90.00% #{97.20 * 0.6}px, 
      95.00% #{95.70 * 0.6}px, 
      100.00% #{92.61 * 0.6}px,
    );
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      bottom: -280px;
      left: 0;
      transform: translateX(-50%);
      margin-left: 50px;
      display: block;
      width: 340px;
      height: 340px;
      mix-blend-mode:multiply;
      background: radial-gradient(#e1e1e1 0%, #fff 70%, #fff 100%);
    }
  }
}
}