body.home {
#service__swiper {
  .swiper {
    padding: 60px 0;
  }
  .swiper__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mq(sp) {
      .is-nav {
        display: none;
      }
    }
  }
}
.service {
  @include mq(pc) {
    padding-bottom: 220px;
  }
  @include mq(sp) {
    padding-top: 135px;
    padding-bottom: 150px;
  }
  .section {
    &__header {
      @include mq(pc) {
        display: flex;
        > * {
          flex: 1;
        }
        p {
          margin-top: 2em;
        }
      }
      @include mq(sp) {

      }
    }
  }
  &__card {
    @include mq(pc) {
      width: 900px;
    }
    &__media {
      @include mq(pc) {
        height: 320px;
      }      
    }
  }
}
}