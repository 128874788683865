body.single-consultant {
    #case__swiper {
        @include case__swiper;
    }

    .mv {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include mq(pc) {
            height: 740px;
        }
        @include mq(sp) {
            height: 400px;
            margin-bottom: 300px;
        }
        .breadcrumb {
            position: absolute;
            top: 140px;
            left: 50%;
            transform: translateX(-600px);
            // color: white;
            color: #969696;
            mix-blend-mode: multiply;
        }
        &__bg {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &__content {
            background-color: white;
            @include mq(pc) {
                position: absolute;
                left: 0;
                bottom: 60px;
                width: 50%;
                padding-left: calc(50% - 600px);
                padding-right: 100px;
                padding-top: 40px;
                padding-bottom: 40px;
            }
            @include mq(sp) {
                width: calc(100% - 30px);
                padding: 30px;
                position: absolute;
                top: 100%;
                transform: translateY(-80px);
            }
        }
        &__title {
            @include h2;
        }
        &__div {
            @include alnum;
            @include font-size(14, 21);
            margin-top: 2em;
        }
        &__name {
            @include font-size(20, 24);
            margin-top: 0.6em;
        }
        &__genre {
            @include font-size(14, 24);
            margin-top: 4.5em;
            color: $fg-gray;
            position: relative;
            &::before {
                content: "専門分野";
                @include font-size(16, 24, 14);
                position: absolute;
                left: 0;
                top: -1.9em;
                right: 0;
            }
            &::after {
                content: "";
                position: absolute;
                left: 6em;
                top: -1.4em;
                right: 0;
                height: 1px;
                background: $fg-gray;
            }
        }
    }
    .is-format {
        @include has-wave-after;
        background: $bg-gradient-gray;
        @include mq(pc) {
            padding-top: 50px;
        }
        @include mq(sp) {
            padding-top: 40px;
        }
    }
    .career {
        position: relative;
        @include mq(pc) {
            padding: 60px 0 50px;
            margin: 70px 0 80px;
        }
        @include mq(sp) {
            padding: 30px 0 20px;
            margin: 60px 0 80px;
        }
        &::before {
            content: "";
            position: absolute;
            left: 4em;
            top: 0;
            right: 0;
            height: 2px;
            background: $bg-gradient;
        }
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: 2px;
            background: $bg-gradient;
        }
        &__title {
            position: absolute;
            left: 0;
            top: -0.5em;
            @include mq(pc) {
                @include font-size(20, 24);
            }
            @include mq(sp) {
                @include font-size(20, 24);
            }
        }
    }
    .fields {
        @include mq(pc) {
            gap: 1.4em;
            padding: 0 60px;
        }
    }
    .field__name {
        width: 18em;
    }
    .links {
        @include has-wave-before;
    }
    .case {
        background: $bg-gradient-gray;
    }
}
