#menu {
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: transform .2s ease;
  background: $bg;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1);
  @include mq(pc) {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    &.is-auto-display {
      transform: translateY(-100%);
      &.is-active {
        transform: translateY(0);
      }
    }
  }
  @include mq(sp) {
    body.home & {
      @include show-on-load;
    }
    body.page-recruitment & {
      transform: translateY(100%);
      &.is-active {
        transform: translateY(0);
      }
    }
  }
}

#menu__close--sp {
  background-color: $fg-gray;
  color: $white;
  width: 215px;
  height: 60px;
  margin: 2em auto 1em;
}
.menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(pc) {
    height: 100px;
    padding: 0 30px;
    &__logo img {
      transform: translateY(-3px); // Why we needs adjust vertical align in spite of align-items is center
      width: 129px;
      height: 42px;
    }
    &__lang {
      margin-left: 45px;
      flex-grow: 1;
      img {
        width: 74px;
        height: 30px;
      }
    }
    &__name {
      margin-left: 45px;
      flex-grow: 1;
    }
    &__underlayer {
      display: none;
    }  
    &__buttons {
      a {
        margin-left: 45px;
        position: relative;
      }
      .menu__link {
        &:hover,
        &.is-current {
          &::after {
            content: '';
            background-color: $primary;
            width: 26px;
            height: 3px;
            display: block;
            position: absolute;
            left: 50%;
            bottom: -12px;
            transform: translateX(-50%);
          }
        }
        .action {
          display: none;
        }  
      }
      .menu__pagelink {
        display: flex;
        gap: 10px;
        &:hover {
          .icon {
            background-color: $black;
            border-color: $black;
            color: white;           
          }
        }
        .icon {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background-color: white;
          border: 1px solid white;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all .2s ease;
          svg {
            width: 7px;
            stroke: currentColor;
          }
        }
      }
      .button {
        padding: 1em 2em;
        @media (hover: hover) {
          &:hover {
            @include button-appearance(shadow);    
          }
        }
      }
    }
    &__wrapper {
      display: flex;
      align-items: center;
    }
  }
  @include mq(sp) {
    padding: 0 20px;
    height: 85px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    display: grid;
    grid-template-rows: 85px 1fr;
    grid-template-columns: 1fr 90px 42px;
    width: 100%;
    background: $bg;
    &__action {
      @include text--s;
      text-align: center; 
      padding: 1em 0;   
      width: 175px;
      grid-column: 2/3;
      grid-row: 1/2;  
    }
    &__logo {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      img {
        width: 83px;
        height: 34px;
      }
    }
    &__lang {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      img {
        width: 66px;
        height: 27px;
      }
    }
    &__name {
      grid-column: 2/3;
      grid-row: 1/2;
    }
    &__control {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
    }
    &__buttons {
      grid-column: 1 / 4;
      grid-row: 2 / 3;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      padding: 2em 8px;
      height: 100%;
      overflow-y: scroll;
      a.button {
        margin-top: 2.5em;
      }
    }
    .site__link {
      margin: 2.5em 0;
      @include text--s;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .icon {
        pointer-events: none;
        background-color: white;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 3px;
          stroke: currentColor;
          stroke-width: 3px;
        }
      }     
    }
    &__pagelink {
      padding: 1.3em 0;
      border-top: 1px solid $border;
      border-bottom: 1px solid $border;
      margin-top: -1px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 10px;
      .icon {
        pointer-events: none;
        box-shadow: $bevel-outside;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 10px;
          height: 5px;
          stroke: currentColor;
        }
      }
      &.is-totop .icon {
        width: 40px;
        height: 40px;
      }
    }
    &__link {
      padding: 1.3em 0;
      border-top: 1px solid $border;
      display: flex;
      width: 100%;
      align-items: center;
      &.is-group {
        position: relative;
        .action {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 35px;
          height: 44px;
          background-image: url(../img/design/icon_plus.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 12px;
        }
        &.is-open {
          padding-bottom: 1.2em;
          .action {
            background-image: url(../img/design/icon_minus.svg);
          }
          + .menu__underlayer {
            height: auto;
          }
        }
      }
      .sp {
        @include button;
        padding: 0 !important;
        color: #323232;
        width: 34px;
        height: 34px;
        margin-right: 30px;
        pointer-events: none;
      }
      img {
        width: 12px;
        height: 12px;
      }
    }
    &__underlayer {
      @include font-size(12, 30);
      height: 0;
      overflow: hidden;
      transition: .3s linear;
      > li:last-child {
        margin-bottom: 1.5em;
      }
      .menu__link {
        border: none;
        padding: 0 0 0 5.2em;
        opacity: .7;
      }
    }
    &__links {
      border-top: 1px solid $border;
      padding: 3em 0;
      display: flex;
      gap: 1.5em;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      @include font-size(12);
    }
  }


  &__control {
    width: 42px;
    height: 42px;
    justify-self: flex-end;
    .button {
      width: 40px;
      height: 40px;
      padding: 0;
      color: #323232;
      img {
        width: 15px;
        height: 15px;
      }
    }
  }
}

@include mq(sp) {
  html.menu-open {
    #menu__open {
      display: none;
    }
    .menu {
      height: 100vh;
      border-radius: 0;
    }
  }
  html:not(.menu-open) {
    #menu__close {
      display: none;
    }
    .menu__buttons {
      display: none;
    }
  }
}