body.single-info {
  main {
    background: $bg-gradient-gray;
  }
  .mv {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include mq(pc) {
        height: 420px;
        gap: 1em;
      }
      @include mq(sp) {
        padding-top: 110px;
        padding-bottom: 30px;
        min-height: 370px;
        gap: .5em;
        justify-content: center;
      }     
    }
    .breadcrumb {
      color: white;
      padding-top: 135px;
      margin-left: -50px;
      margin-bottom: 10px;
    }
    h2 {
      @include h2;
      color: white;
    }
    .chip {
      &.is-dark {
        @include chip(16);
      }
      &.is-light {
        @include chip;
      }
    }
    &__meta {
      display: flex;
      align-items: center;
      gap: 1em;
    }
    &__date {
      @include text--xs;  
      color: white;
    }
  }
  .is-format {
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 50px 0;
    }
    p {
      margin: 30px 0;
    }
    .row {
      @include mq(pc) {
        grid-template-columns: 360px 360px;
      }
    }
  }
  .news {
    padding-bottom: 0;
  }
}
