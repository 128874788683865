
.news {
  &.section {
    @include mq(pc) {
      padding: 90px 0 110px;
    }
    @include mq(sp) {
      padding: 80px 0;
    }
  }
  .section {
    &__content {
      @include mq(pc) {
        margin: 70px 0 50px;
      }
      @include mq(sp) {
        margin: 45px 0;
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(pc) {
      gap: 50px;
    }
    @include mq(sp) {
      flex-direction: column;
      gap: 30px;
    }
  }
  &__card {
    margin: 30px 0;
    box-shadow: $bevel-outside;
    border-radius: $border-radius;
    overflow: hidden;
    display: flex;
    @include mq(pc) {
      height: 150px;
      &:hover {
        .news__card__media::after {
          opacity: 0;
        }
      }
    }
    &__media {
      @include mq(sp) {
        display: none;
      }
      position: relative;
      flex-shrink: 0;
      width: 210px;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $fg-gray-light;
        mix-blend-mode: multiply;
        z-index: 12;
        transition: all .2s ease;
      }
      img {
        position: relative;
        z-index: 11;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: self-start;
      @include mq(pc) {
        padding: 20px 30px 0;
        flex-grow: 1;
      }
      @include mq(sp) {
        padding: 20px;
      }
    }
    &__date {
      @include font-size(12, 21);
      opacity: .7;
    }
    &__title {
      @include font-size(14, 24);
      margin: .5em 0;
      display: -webkit-box;
      box-orient: vertical;
      overflow: hidden;
      width: 100%;
      @include mq(pc) {
        line-clamp: 2;
      }
      @include mq(sp) {
        line-clamp: 3;
      }
    }
    &__category {
      display: flex;
      gap: 10px;
      letter-spacing: 0;
      @include mq(sp) {
        margin-top: .3em;
      }
      div {
        @include font-size(12, 21);
        border: 1px solid $border;
        border-radius: 1.1em;
        background-color: $white;
        color: $fg-gray;
        padding: .2em 1em;
        &:first-child {
          background-color: $black;
          color: white;
        }
      }
    }
    &__action {
      display: flex;
      align-items: center;
      padding-right: 20px;
    }
  }
}